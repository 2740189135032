import React, { useRef, useState } from "react";
import "./Reactions.scss";
import { useTranslation } from "react-i18next";
import { IReactionChip, IReactions } from "./interfaces/Reactions.interface";
import { useAutoSetInitialState, useNotifier } from "../../../hooks";
import { UserReactionsList } from "./components/UserReactionsList";
import { AddReactionButton } from "./components/AddReactionButton";
import { Popover } from "../../uiKit";
import { ReactionDropdown } from "./components/generalDropdown/ReactionDropdown";

export const Reactions = <T,>(props: IReactions<T>) => {
  const { t } = useTranslation();
  const notifier = useNotifier();

  const reactionDropdownRef = useRef<{ onClosePicker: () => void; onOpenGeneralPicker: () => void }>();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

  const [reactionsArr, setReactionsArr] = useAutoSetInitialState<T>(props.reactions);

  const handleSendReaction = (reaction: string) => {
    setIsDisabled(true);
    try {
      props.strategy
        .onSendReaction(reaction)
        .then((res) => res && setReactionsArr(res as T[]))
        .finally(() => setIsDisabled(false));
    } catch (e) {
      notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });
      setIsDisabled(false);
    }
  };

  const handleOpenDropdownChange = (value: boolean) => {
    setIsOpenDropdown(value);
    if (!value) reactionDropdownRef.current?.onClosePicker();
    else reactionDropdownRef.current?.onOpenGeneralPicker();
  };
  const handleOpenReactionsList = () => setIsOpenDropdown(!isOpenDropdown);
  const handleClose = () => setIsOpenDropdown(false);

  const handleReact = (reaction: string) => {
    isOpenDropdown && handleClose();
    handleSendReaction(reaction);
  };

  const reactionChips: IReactionChip[] = props.strategy.groupReactionChips(reactionsArr as T[]);

  return (
    <div
      className="d-stack-row spacing-1 justify-end align-center"
      style={{
        paddingRight: "5px"
      }}
    >
      <UserReactionsList
        reactions={reactionChips}
        disabled={isDisabled}
        onClickReaction={handleReact}
        isNewStyle={props.isNewStyle}
      />
      <Popover
        overlayClassName="reaction-overlay"
        content={
          <ReactionDropdown
            ref={reactionDropdownRef}
            onReact={handleReact}
            onClose={handleClose}
            isDisabled={isDisabled}
            isShowFullEmoji={props.isShowFullEmoji}
          />
        }
        arrowContent={undefined}
        arrow={{ pointAtCenter: false }}
        trigger={["hover", "click"]}
        open={isOpenDropdown}
        onOpenChange={handleOpenDropdownChange}
      >
        <AddReactionButton
          disabled={isDisabled}
          onClick={handleOpenReactionsList}
          isNewStyle={props.isNewStyle}
          isBorder={props.isBorder}
        />
      </Popover>
    </div>
  );
};
