import React from "react";
import "./SettingsModulesPage.scss";
import { useRootStore } from "../../../hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { Collapse, CollapsePanel } from "../../uiKit";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { ModuleIntervalList } from "../../modules/pages/settings/moduleIntervalList/ModuleIntervalList";
import { ModuleRegulationRestudyAccess } from "../../modules/pages/settings/moduleRegulationRestudyAccess/ModuleRegulationRestudyAccess";

function SettingsModulesPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  return (
    <>
      <PageHeader title={t("common:page_title.settings_modules")} />
      <PageContent tabs={authStore.getInitialInfo?.settingsTabs}>
        <div className="d-stack-column spacing-2">
          <Collapse className="settings-module-page__collapse" defaultActiveKey={1}>
            <CollapsePanel key={1} header={t("common:tab.orgchart.metrics")}>
              <ModuleIntervalList />
            </CollapsePanel>
          </Collapse>
          <Collapse className="settings-module-page__collapse" defaultActiveKey={1}>
            <CollapsePanel key={1} header={t("common:tab.orgchart.regulations")}>
              <ModuleRegulationRestudyAccess />
            </CollapsePanel>
          </Collapse>
        </div>
      </PageContent>
    </>
  );
}

export default observer(SettingsModulesPage);
