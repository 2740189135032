import React, { useCallback, useMemo } from "react";
import "./IssueOptions.scss";
import { IIssueOptions } from "./IssueOptions.interface";
import { IssueActionConsts } from "../../../types/consts";
import { DropdownMenu } from "../../../../../service/dropdownMenu/dropdownMenu";
import { useTranslation } from "react-i18next";
import { useRootStore } from "../../../../../../hooks";
import { IssueQueryKeys } from "../../../../../../constants/issueTypeKeys";
import { useNavigate } from "react-router-dom";

export const IssueOptions = (props: IIssueOptions): JSX.Element => {
  const navigate = useNavigate();
  const { issueInitDataStore } = useRootStore();

  const { t } = useTranslation();
  const actionsHandler = (key: IssueActionConsts): (() => void) => {
    switch (key) {
      case IssueActionConsts.delete: {
        return () => props.toggleDeleteConfirm(true);
      }
      case IssueActionConsts.withdraw: {
        return () => props.onOpenWithdrawIssueDialog();
      }
      case IssueActionConsts.copy: {
        return props.onOpenCopyIssueDialog;
      }
      case IssueActionConsts.sendForArchive: {
        return props.onOpenSendForArchiveDialog;
      }
      case IssueActionConsts.archive: {
        return props.onOpenSendForArchiveDialog;
      }
      case IssueActionConsts.unArchive: {
        return props.onHandleUnArchive;
      }
      // case IssueActionConsts.reject: {
      //   return () => props.onOpenIssueRejectDialog();
      // }
      // case IssueActionConsts.sendAgain: {
      //   return () => props.onSendIssueAgain();
      // }
      // case IssueActionConsts.doByMyself: {
      //   return () => props.onDoingIssueByMyself();
      // }
      default: {
        return () => {};
      }
    }
  };

  const handleAddRelatedTask = useCallback(() => {
    issueInitDataStore.setRelatedTaskLink = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${window.location.href}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
    navigate({ search: `?${IssueQueryKeys.taskCreate}=${true}` });
  }, []);

  const popupItems = useMemo(() => {
    const addRelatedTask = {
      id: -1,
      text: t("ui:issueActions.add_related_task"),
      action: handleAddRelatedTask,
    }
    return [addRelatedTask, ...(props.actions ?? [])
      .filter((item) => !item.baseAction?.displayInFlowComboBox)
      .map((item) => ({
        id: item.id!,
        text: item.baseAction?.name!,
        action: actionsHandler(item.baseAction?.key as IssueActionConsts),
      }))];
  }, [props.actions]);

  return <DropdownMenu items={popupItems} />;
};
