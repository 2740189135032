import React, { memo, useEffect, useState } from "react";
import { ISelectApproverDialog } from "./SelectApproverDialog.interface";
import { SelectApproverDialogView } from "./SelectApproverDialogView";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const SelectApproverDialog = memo((props: ISelectApproverDialog) => {
  // const { authStore, appStore } = useRootStore();
  const [approverId, setApproverId] = useState<number | null>(null);
  /*const [orgchartId, setOrgchartId] = useState<number | null>(null);*/
  const [resetTestResults, setResetTestResults] = useState<boolean>(false);
  const [isPublishWithoutApproval, setIsPublishWithoutApproval] = useState<boolean>(false);
  const [urgency, setUrgency] = useState<string | undefined>(undefined);

  //const currentUserId: number = authStore.getInitialInfo?.identity?.id!;
  const handleCloseDialog = () => {
    setApproverId(null);
    props.onClose();
  };

  const handleChangeUrgency = (value: string | null) => value && setUrgency(value);

  // const handleChangeOrgchartId = (id: string | number) => setOrgchartId(+id);

  const handleChangeResetTestResults = (e: CheckboxChangeEvent) => setResetTestResults(e.target.checked);
  const handleSelectApprover = (id: number | null) => setApproverId(id);

  const handleChangePublishWithoutApproval = (e: CheckboxChangeEvent) => setIsPublishWithoutApproval(e.target.checked);

  const handleSubmitForApproval = () => {
    (approverId || isPublishWithoutApproval) && props.onSuccess(approverId ?? null, resetTestResults, isPublishWithoutApproval, urgency);
  };

  useEffect(() => {
    if (props.nearestManagerId) setApproverId(props.nearestManagerId);
    // else if (!props.nearestManagerId && currentUserPermissionApprove) setApproverId(currentUserId)
  }, []);

  return (
    <SelectApproverDialogView
      open={props.open}
      approverId={approverId}
      withUrgency={props.withUrgency}
      urgency={urgency}
      onChangeUrgency={handleChangeUrgency}
      resetTestResults={resetTestResults}
      isPublishWithoutApproval={isPublishWithoutApproval}
      withCheckbox={props.withCheckbox}
      isShowPublishWithoutApprovalCheckbox={props.isShowPublishWithoutApprovalCheckbox ?? false}
      onChangeResetTestResults={handleChangeResetTestResults}
      onChangePublishWithoutApproval={handleChangePublishWithoutApproval}
      onSelectApproverId={handleSelectApprover}
      onClose={handleCloseDialog}
      onSubmitForApproval={handleSubmitForApproval}
    />
  );
});