import React, { useState } from "react";
import { ITopAppBarMenu } from "./TopAppBarMenu";
import "./TopAppBarMenu.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Avatar, Dropdown, Icon, Text } from "../../../uiKit";
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown";

function TopAppBarMenuView(props: ITopAppBarMenu) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Dropdown
        trigger={["click"]}
        align={{ offset: [0, 4] }}
        overlayClassName="top-app-bar-menu-overlay"
        onOpenChange={setIsOpen}
        items={[
          ...props.menuItems.map((menuItem) => ({
            key: String(menuItem.id),
            text: menuItem.title,
            onClick: () => menuItem.action(),
            icon: menuItem.icon,
          })),
        ]}
      >
        <div className="top-app-bar-menu_container d-flex align-center px-3">
          <Avatar
            size={36}
            text={[props.userData?.lastName, props.userData?.firstName]}
            color={props.userData?.color}
            src={props.userData?.avatar?.image?.url}
          />
          <div
            className="flex-grow-1 d-flex flex-column mx-3"
            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
          >
            <Text className="__name" children={props.userData ? props.userData?.name : t("text:loading_data")} />
            <Text className="__email" children={props.userData?.email ?? ""} />
          </div>
          <Icon className={`__icon ${isOpen ? "__open" : ""}`}>
            <FiChevronDown />
          </Icon>
        </div>
      </Dropdown>
    </>
  );
}

export default observer(TopAppBarMenuView);
