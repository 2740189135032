import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../../containers/issueUpdateContainer/IssueUpdateContainer.scss";
import { IssueHistorySenderBlock } from "./components/IssueHistorySenderBlock/IssueHistorySenderBlock";
import { IssueAceptHistoryLocalesConst, IssueHistoryConst, IssueIndicatorsKeysConst } from "../../../types/consts";
import { IssueHistoryListComments } from "./components/issueHistoryListComments/IssueHistoryListComments";
import { IIssueHistoryModule } from "./IssueHistory.interface";
import { IssueCommentAttachmentDto, IssueHistoryDto, StaticFileDto } from "../../../../../../api";
import { api } from "../../../../../../services";
import { initialEditorContent } from "../../../../../../utils/textEditor";
import { useNotifier, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { DeleteCommentDialog } from "../../../../dialogs/deleteCommentDialog/DeleteCommentDialog";
import SubheaderText from "../../../../../elements/subheaderText/SubheaderText";
import { BroadcastChannel } from "broadcast-channel";
import { customEvent } from "../../../../../../helpers/eventBus";

const IssueHistoryModuleMemo = (props: IIssueHistoryModule) => {
  const { appStore } = useRootStore();
  const { t } = useTranslation();
  const notifier = useNotifier();
  // const editorRef = useRef<{ clear: () => void }>();
  const editorRef = useRef<{ isEmptyEditor: () => boolean; resetEditor: () => void }>();
  const attachmentsCommentCopy = useRef<StaticFileDto[]>([]);

  // const [comment, setComment] = useState<Value>(initialContent);
  const [comment, setComment] = useState<string>(initialEditorContent);
  const [attachments, setAttachments] = useState<StaticFileDto[]>([]);
  const [isLoadingSend, setIsLoadingSend] = useState<boolean>(false);
  const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = useState<boolean>(false);
  const [issueHistoryId, setIssueHistoryId] = useState<number | null>(null);
  const issueChannel = new BroadcastChannel("issue");

  const handlerApiError = () => notifier.show({ message: t("notifier:error.something_wrong"), theme: "error" });

  const driverReports = usePagingWithController(api.controlSessionHistory, {
    issueId: props.issueId,
  });

  const isAllowAcceptButton = useMemo(
    () => !!props.indicators?.find((item) => item.key === IssueIndicatorsKeysConst.HasMessage),
    [props.indicators]
  );

  const setAttachmentsCommentSync = useCallback(
    (v: StaticFileDto[]) => {
      setAttachments(v);
      attachmentsCommentCopy.current = v;
    },
    [setAttachments]
  );

  // const handleChangeComment = (comment: Value) => setComment(comment);
  const handleChangeComment = useCallback((comment: string) => {
    setComment(comment);
  }, []);

  const handleDeleteAttachmentById = (fileId: number) => {
    if (fileId == null) return;
    setAttachmentsCommentSync((attachments ?? []).filter((x) => x.id != fileId));
  };

  const handleAcceptIssueHistory = useCallback(async (): Promise<IssueHistoryDto | void> => {
    const localeType = appStore.getAppLocale;
    const createDto: IssueHistoryDto = {
      issueId: props.issueId,
      comment: {
        text:
          localeType === IssueAceptHistoryLocalesConst.Ru.locale
            ? IssueAceptHistoryLocalesConst.Ru.message
            : IssueAceptHistoryLocalesConst.En.message,
      },
    };
    const r = await api.issueHistory.create(createDto);
    if (r == null) return handlerApiError();
    // editorRef.current?.clear();
    setComment(initialEditorContent);
    setAttachments([]);
    return r;
  }, [appStore]);

  const handleCreateIssueHistory = useCallback(
    async (type, text) => {
      setIsLoadingSend(true);
      const attachments = [
        ...attachmentsCommentCopy.current.map((f, index) => {
          return {
            fileId: f.id,
            order: index,
          };
        }),
      ];

      const createDto: IssueHistoryDto = {
        issueId: props.issueId,
        comment: {
          text: !editorRef.current?.isEmptyEditor()! ? text ?? comment : undefined,
          attachments: attachments ?? [],
        },
      };
      if (type === IssueHistoryConst.Message) {
        createDto.comment!.recipients = [
          {
            // @ts-ignore
            userId: props.isExecutor ? props?.createdByUserId : props?.executorUserId,
          },
        ];
      }

      const r = await api.issueHistory.create(createDto);
      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueHistory",
      });
      setIsLoadingSend(false);
      if (r == null) return handlerApiError();
      setComment(initialEditorContent);
      customEvent.dispatch("reloadIssueHistory", { issueId: props.issueId });
      setAttachmentsCommentSync([]);
      editorRef.current?.resetEditor();
    },
    [
      comment,
      handlerApiError,
      issueChannel,
      props?.createdByUserId,
      props?.executorUserId,
      props.isExecutor,
      props.issueId,
      setAttachmentsCommentSync,
    ]
  );

  const handleEditComment = useCallback(
    async (editorContent: string, attachmentsList?: IssueCommentAttachmentDto[]) => {
      if (issueHistoryId) {
        const attachments = [
          ...(attachmentsList ?? []).map((f, index) => {
            return {
              fileId: f.id,
              order: index,
            };
          }),
        ];
        const r = await api.issueHistory.edit(issueHistoryId, { comment: { text: editorContent, attachments } });
        if (r == null) {
          notifier.show({
            message: t("notifier:error.something_wrong"),
            theme: "error",
          });
          return;
        }
        notifier.show({
          message: t("notifier:success.saved"),
          theme: "success",
        });

        await issueChannel.postMessage({
          issueId: props.issueId,
          type: "issueHistory",
        });
      }
    },
    [issueChannel, issueHistoryId, notifier, props.issueId, t]
  );

  const handleDeleteComment = useCallback(async () => {
    if (issueHistoryId) {
      const r = await api.issueHistory.del(issueHistoryId);
      if (r == null) {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
        return;
      }
      notifier.show({
        message: t("notifier:success.deleted"),
        theme: "success",
      });

      await issueChannel.postMessage({
        issueId: props.issueId,
        type: "issueHistory",
      });
    }
    // setIsEditMode(false);
    setOpenDeleteCommentDialog(false);
  }, [issueChannel, issueHistoryId, notifier, props.issueId, t]);

  const handleOpenDeleteCommentDialog = useCallback((id: number) => {
    setIssueHistoryId(id);
    setOpenDeleteCommentDialog(true);
  }, []);
  const handleCloseDeleteCommentDialog = useCallback(() => {
    setOpenDeleteCommentDialog(false);
  }, []);
  const handleIssueHistoryIdChange = useCallback((id: number) => {
    setIssueHistoryId(id);
  }, []);

  useEffect(() => {
    driverReports.restart();
  }, []);

  return (
    <>
      {openDeleteCommentDialog && (
        <DeleteCommentDialog
          onClose={handleCloseDeleteCommentDialog}
          open={openDeleteCommentDialog}
          onDelete={handleDeleteComment}
          title={t("ui:title.confirm_delete_comment")}
          description={t("ui:text.confirm_delete_comment")}
        />
      )}
      <div className="flex-grow-1 pl-5" style={{ overflow: "auto", width: "45%" }}>
        {/*TODO: Empty Character*/}
        <div style={{ visibility: "hidden" }} children={<SubheaderText text={"ㅤ"} />} />
        <IssueHistorySenderBlock
          ref={editorRef}
          issueId={props.issueId}
          isLoadingSend={isLoadingSend}
          allowedCommentsTypes={props.allowedToSendCommentTypes}
          isAllowAceptButton={isAllowAcceptButton}
          onChangeComment={handleChangeComment}
          commentIssue={comment}
          attachments={attachments}
          setAttachments={setAttachmentsCommentSync}
          onSendComment={handleCreateIssueHistory}
          onDeleteAttachmentById={handleDeleteAttachmentById}
          onAcceptIssueHistory={handleAcceptIssueHistory}
        />
        <IssueHistoryListComments
          loadNextReports={driverReports.loadNext}
          totalItemsDriverReports={driverReports.info.totalItems}
          isLoadingReports={driverReports.info.isLoading}
          isDoneReports={driverReports.info.isDone}
          issueId={props.issueId}
          driverReports={driverReports.items}
          onOpenDeleteCommentDialog={handleOpenDeleteCommentDialog}
          onIssueHistoryIdChange={handleIssueHistoryIdChange}
          onEditComment={handleEditComment}
          currentStatusId={props.currentStatusId}
        />
      </div>
    </>
  );
};

export const IssueHistoryModule = React.memo(IssueHistoryModuleMemo);
