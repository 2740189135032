import React, { memo } from "react";
import { IIssueSwitchStatusView } from "./IssueSwitchStatus.interface";
import { Select, SelectOption, Spin } from "../../uiKit";

export const IssueSwitchStatusView = memo((props: IIssueSwitchStatusView) => {

  return (
    <>
      <Spin
        size="small"
        wrapperClassName={`issue-switch-status issue-switch-background__${props.currentStatus?.colorSchemeKey}`}
        spinning={props.isLoading}
      >
        <Select
          size="middle"
          style={{ borderColor: "red" }}
          dropdownStyle={{ minWidth: "max-content" }}
          onChange={props.onChange}
          value={props.currentStatus?.name}
          fullWidth
          disabled={props.isDisabled}
          // className="mb-1"
        >
          {/*{props.nextStatuses?.map((status) => (*/}
          {/*  <SelectOption key={status.id} value={status.id}>*/}
          {/*    {status.name}*/}
          {/*  </SelectOption>*/}
          {/*))}*/}
          {props.actions
            ?.filter((action) => action.baseAction?.displayInFlowComboBox)
            .map((action) => (
              <SelectOption key={action.id} value={action.baseAction?.key ?? undefined}>
                {action.baseAction?.name}
              </SelectOption>
            ))}
        </Select>
      </Spin>
    </>
  );
});
