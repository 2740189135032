import React, { useCallback } from "react";
import "./EducationCard.scss";
import { IEducationCardView } from "./EducationCard.interface";
import { Card, CardMeta } from "../../../../../uiKit";
import { Progress } from "antd";
import { EducationCardCover } from "./EducationCardCover/EducationCardCover";

export const EducationCardView = (props: IEducationCardView) => {
  const Media = (
    <div style={{ position: "relative" }}>
      {props.cover ?? (
        <EducationCardCover
          title={props.educationItem.name ?? ""}
        />
      )}

      {props.indicator && (
        <div className={`education-card-indicator education-card-indicator_${props.indicator.status}`}>
          {props.indicator.text}
        </div>
      )}

    </div>
  );

  const handleClick = useCallback(() => {
    // props.educationItem.isAvailable &&
    props.onOpenTrainingDialog({
      trainingKey: props.trainingKey,
      videoId: props.educationItem.videoId,
      itemId: props.educationItem.id,
    })
  }, []);

  return (
    <Card
      hoverable
      cover={Media}
      onClick={handleClick}
      variant="secondary"
      // className={`education-card ${!props.educationItem.isAvailable && "education-card-disabled"}`}
      className={`education-card`}
    >
      <CardMeta
        title={props.educationItem.name}
        description={props.educationItem.description}
        // description={
        //   !props.educationItem.isAvailable ? (
        //     <Alert type="error" message={t("text:no_permissions")} />
        //   ) : (
        //     props.educationItem.description
        //   )
        // }
      />
      {props.isShowProgress && (
        <Progress
          className="education-card__progress"
          style={{ fontSize: "14px" }}
          percent={props.educationItem.progress}
          status={"normal"}
        />
      )}
    </Card>
  );
};
