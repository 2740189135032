import React, { forwardRef, useCallback, useMemo } from "react";
import { Button, Dialog, DialogActions, Spin, Text } from "../../../uiKit";
import { IIssueCommonViewDialog, IssueFormKeys, IssueFormType } from "./IssueCommonDialog.interface";
import { useTranslation } from "react-i18next";
import { getDialogConfig } from "./helpers";
import { CenterPageContentLayout } from "../../layouts/centerPageContentLayout/CenterPageContentLayout";
import { IssueCreateContainer } from "../../forms/issueCreateEditForms/containers/issueCreateContainer/IssueCreateContainer";
import { IssueUpdateContainer } from "../../forms/issueCreateEditForms/containers/issueUpdateContainer/IssueUpdateContainer";
import { IssueTypeKeys } from "../../../../constants/issueTypeKeys";
import clsx from "clsx";
import { FlowTypeColorSchemeKey } from "../../pages/communications/constants/keys";
import { SwitchFieldType } from "../../forms/types/consts";
import { useRootStore } from "../../../../hooks";

const guideIcon = (issueType: IssueTypeKeys, formType: IssueFormType) => {
  return null;
};

const DialogInsideCornersHack = ({ colorKey }: { colorKey: string }) => {
  return (
    <>
      {[0, 1, 2, 3].map((side, index) => (
        <div
          key={index}
          style={{
            transform: `rotate(${index * 90}deg)`,
            position: "absolute",
            left: side == 0 || side == 3 ? 0 : undefined,
            right: side == 1 || side == 2 ? 0 : undefined,
            top: side == 0 || side == 1 ? 0 : undefined,
            bottom: side == 2 || side == 3 ? 0 : undefined,
            width: "16px",
            height: "16px",
            background: `var(--color-${colorKey}-base)`,
          }}
          children={
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "16px",
                height: "16px",
                borderRadius: "8px 0 0 0",
                background: "var(--color-layout-drawer)",
              }}
            />
          }
        />
      ))}
    </>
  );
};

export const IssueCommonViewDialog = forwardRef((props: IIssueCommonViewDialog, ref) => {
  const { authStore } = useRootStore();

  const { t } = useTranslation();
  const dialogConfig = getDialogConfig(t, props.formType, props.issueType);
  const isCreateMod = useMemo(() => props.formType === IssueFormKeys.create, [props.formType]);

  const handleGetTitle = useCallback(() => {
    switch (true) {
      case isCreateMod:
        return (
          <div className="d-flex align-center">
            {dialogConfig.title} <span className="px-1" /> {guideIcon(props.issueType, props.formType)}
          </div>
        );
      case !!props.updater.currentState?.calculated?.border?.name:
        const color =
          props.updater.currentState?.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Background
            ? "var(--color-calendar-text-base)"
            : "var(--color-white)";
        return (
          <Text
            weight={400}
            children={props.updater.currentState?.calculated?.border?.name}
            style={{ color }}
          />
        );
      default:
        return undefined;
    }
  }, [
    dialogConfig.title,
    isCreateMod,
    props.formType,
    props.issueType,
    props.updater.currentState?.calculated?.border?.name,
  ]);

  const handleClose = useCallback(() => {
    console.log("handleClose");
    if ((props.updater.currentState?.initiatorUserId === authStore.getInitialInfo?.identity?.id ||
      props.updater.currentState?.createdByUserId === authStore.getInitialInfo?.identity?.id ||
      props.updater.currentState?.executorUserId === authStore.getInitialInfo?.identity?.id) && props.formType === IssueFormKeys.update &&
      props.updater.currentState?.fields?.find((f) => f.key === SwitchFieldType.isManagerApprovalRequired)?.valueBool &&
      !props.updater.currentState?.timePlanForApproval
    ) {
      return false;
    }
    return props.onClose();
  }, [
      props.updater.currentState?.fields,
      props.updater.currentState?.timePlanForApproval,
      props.updater.currentState?.initiatorUserId,
      props.updater.currentState?.createdByUserId,
      props.updater.currentState?.executorUserId,
    ]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      scrollStrategy="dialogBody"
      closable={false}
      width={dialogConfig.dialogWidth}
      bodyStyle={{
        overflow: !isCreateMod ? "hidden" : "auto",
      }}
      className={clsx("issue-common-dialog", {
        __warning: props.updater.currentState?.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Warning,
        __primary: props.updater.currentState?.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Primary,
        __success: props.updater.currentState?.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Success,
        __background: props.updater.currentState?.calculated?.border?.colorSchemeKey === FlowTypeColorSchemeKey.Background,
      })}
      title={handleGetTitle()}
    >
      {Object.values(FlowTypeColorSchemeKey).includes(
        props.updater.currentState?.calculated?.border?.colorSchemeKey as FlowTypeColorSchemeKey
      ) && <DialogInsideCornersHack colorKey={props.updater.currentState?.calculated?.border?.colorSchemeKey ?? ""} />}
      {props.isLoading ? (
        <div style={{ minHeight: 600, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CenterPageContentLayout horizontal>
            <Spin size="large" />
          </CenterPageContentLayout>
        </div>
      ) : (
        <>
          {props.formType === IssueFormKeys.create && props.updater.currentState && (
            <IssueCreateContainer
              updater={props.updater}
              onCreate={props.onCreate}
              isLoading={props.isLoading}
              customFields={props.customFields}
              issueType={props.issueType}
              ref={ref}
            />
          )}
          {props.formType === IssueFormKeys.update && (
            <IssueUpdateContainer
              isLoading={props.isLoading}
              title={dialogConfig.title}
              issueId={props.issueId!}
              issueType={props.issueType}
              updater={props.updater}
              onClose={handleClose}
              onRedirectToOtherIssue={props.onReopen!}
            />
          )}
        </>
      )}
      {props.formType === IssueFormKeys.create && (
        <DialogActions>
          <>
            <Button onClick={handleClose} type="text">
              {t("ui:button.cancel")}
            </Button>
            <Button loading={props.createBtnIsLoading} onClick={props.onClickCreate} type="primary">
              {"creteBtnText" in dialogConfig ? dialogConfig.creteBtnText : ""}
            </Button>
          </>
        </DialogActions>
      )}
    </Dialog>
  );
});
