import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import "./DriverPrePage.scss";
import { useDateHelpers, useRootStore } from "../../../hooks";
import { api } from "../../../services";
import React, { useEffect, useState } from "react";
import "./DriverAdminPage.scss";
import { AppTabsByPage } from "../../../utils/appLinksAndTabs";
import { usePagingWithController } from "../../../hooks/usePaging";
import Visibility from "visibilityjs";
import { Avatar, Card, Empty, Select, Switch, Text } from "../../uiKit";
import { observer } from "mobx-react-lite";
import { AutocompleteModel, AutocompleteModelPagingModel } from "../../../api";
import { FiltersContainer } from "./components/filtersContainer/FiltersContainer";
import { ConfigKeys, IFiltersContainerParams } from "./components/filtersContainer/FiltersContainer.interface";
import { ColorModeKeys } from "../../elements/counterChip/misc/keys";
import { CounterChip } from "../../elements/counterChip/CounterChip";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";

export interface IDriverAdminHistoryPageFilters extends IFiltersContainerParams {
  selectedCompanyGroupId: number | null;
}

function DriverAdminHistoryPage() {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const dateHelpers = useDateHelpers();
  const [driverAdminFilterGapInHours, setDriverAdminFilterGapInHours] = useState<number | null>(null);
  const [companyGroupData, setCompanyGroupData] = useState<AutocompleteModelPagingModel | null>(null);

  const [pageFilters, setPageFilters] = useState<IDriverAdminHistoryPageFilters>({
    phoneNumber: "",
    selectedCompanyGroupId: null,
    userName: undefined,
    period: undefined,
  });

  // const debouncedFilters = useDebounce(pageFilters, 700);
  //
  // useEffect(() => {
  //   initialFetch();
  // }, [debouncedFilters]);

  const minute = 60 * 1000;

  const initialFetch = () => {
    usersHistory.reset();
    usersOrders.reset();
    usersHistory.restart();
    usersOrders.restart();
  };

  const handleReloadPage = () => {
    if (Visibility.isSupported()) {
      initialFetch();
    }
  };

  const usersOrders = usePagingWithController(
    api.userShort,
    {
      ignoreContext: true,
      driverAdminFilter: "order",
      orderBy: "control_session_date",
      order: "asc",
      companyGroupId: pageFilters.selectedCompanyGroupId ?? null,
      phoneNumber: (pageFilters.phoneNumber ?? "").trim().length > 0 ? pageFilters.phoneNumber : null,
      driverAdminFilterGapInHours: driverAdminFilterGapInHours,
      name: pageFilters.userName,
    },
    { pageSize: 40 }
  );
  const usersHistory = usePagingWithController(
    api.userShort,
    {
      ignoreContext: true,
      companyGroupId: pageFilters.selectedCompanyGroupId ?? null,
      driverAdminFilter: "history",
      phoneNumber: (pageFilters.phoneNumber ?? "").trim().length > 0 ? pageFilters.phoneNumber : null,
      orderBy: "control_session_date",
      driverAdminFilterDateFrom: pageFilters.period?.from,
      driverAdminFilterDateTo: pageFilters.period?.to,
      name: pageFilters.userName,
    },
    { pageSize: 40 }
  );

  const loadCompanyGroupData = async () => {
    const r = await api.companyGroup.autocomplete({
      hasAdminUserId: authStore.getInitialInfo?.identity?.id,
      pageSize: 1000,
    });
    if (r == null) return;
    setCompanyGroupData(r);
    setPageFilters({ ...pageFilters, selectedCompanyGroupId: (r?.items?.[0]?.id as number) ?? null });
  };
  const handleUserCardOpen = (userId: number) => {
    openInNewTab("/driver/user/" + userId);
  };

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    // usersOrders.restart();
    // usersHistory.restart();
    loadCompanyGroupData();
    const visibilityHandlerId = Visibility.every(minute * 5, handleReloadPage);
    return () => {
      Visibility.stop(visibilityHandlerId);
    };
  }, []);

  useEffect(() => {
    pageFilters.selectedCompanyGroupId && initialFetch();
  }, [pageFilters.selectedCompanyGroupId]);

  useEffect(() => {
    if (pageFilters.selectedCompanyGroupId) {
      usersOrders.reset();
      usersOrders.restart();
    }
  }, [driverAdminFilterGapInHours]);

  return (
    <>
      <PageHeader>
        <Select
          size="middle"
          items={(companyGroupData?.items ?? []).map((i: AutocompleteModel) => ({
            id: i.id as number,
            text: i.text as string,
            value: i.id as number,
          }))}
          value={pageFilters.selectedCompanyGroupId}
          onChange={(val) => setPageFilters({ ...pageFilters, selectedCompanyGroupId: Number(val) })}
          style={{ minWidth: "250px" }}
        />
        <FiltersContainer
          params={pageFilters}
          isLoading={usersOrders.info.isLoading || usersHistory.info.isLoading}
          onRefresh={initialFetch}
          config={[ConfigKeys.userName, ConfigKeys.period, ConfigKeys.phoneNumber]}
          // @ts-ignore
          onChange={setPageFilters}
        />
        <div className="flex-grow-1" />
      </PageHeader>
      <PageContent
        isFilled={false}
        useTranslationInTabs
        tabs={AppTabsByPage["driver"]}
        isLoading={usersOrders.info.isLoading || usersHistory.info.isLoading}
      >
        <div className="driver-admin-page__wrapper">
          <Card
            className="mr-1 flex-grow-1 flex-shrink-1"
            style={{ flexBasis: "50%" }}
            title={
              <div className="d-flex align-center">
                {t("common:driver.history")}
                {usersHistory.info.totalItems ? (
                  <CounterChip
                    colorMode={ColorModeKeys.primary}
                    count={usersHistory.info.totalItems}
                    className="ml-2"
                  />
                ) : null}
              </div>
            }
            size="default"
            scrollStrategy="cardBody"
          >
            {usersHistory.items?.length > 0 ? (
              <div className="d-stack-column spacing-2">
                {/*{statsData.map((item) => (*/}
                {/*  <React.Fragment key={item.title}>*/}
                {/*    <DriverStatisticCard digit={item.digit} title={item.title} subtitle={item.subtitle} />*/}
                {/*  </React.Fragment>*/}
                {/*))}*/}
                {usersHistory.items.map((u) => (
                  <Card
                    // hoverable
                    // isShadowed={false}
                    clickable
                    onClick={() => handleUserCardOpen(u.id as number)}
                    variant="secondary"
                  >
                    <div className="d-flex flex-nowrap align-center">
                      <Avatar size={36} color={u.color} src={u.avatar?.image?.url} text={[u?.lastName, u?.firstName]} />
                      <div className="flex-grow-1 d-stack-column mx-3" style={{ overflow: "hidden" }}>
                        <div className="d-stack-column align-start">
                          <Text
                            weight={500}
                            size="16px"
                            children={(u?.name ?? "").trim().length > 0 ? u?.name : u?.email}
                          />
                          <Text
                            children={`${t("common:driver.last_interaction")}: ${
                              u.dateControlSessionLastInteraction != null
                                ? dateHelpers.formatDate(u.dateControlSessionLastInteraction, {
                                    formatObject: { month: "short" },
                                  })
                                : t("common:driver.no_data")
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                <ScrollTrigger
                  onIntersection={() => usersHistory.loadNext()}
                  hidden={usersHistory.info.isDone}
                  marginTop={usersHistory.items.length > 0}
                />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
          <Card
            className="ml-1 flex-grow-1 flex-shrink-1"
            style={{ flexBasis: "50%" }}
            title={
              <div className="d-flex align-center">
                {t("common:driver.in_queue")}
                {usersOrders.info.totalItems ? (
                  <CounterChip colorMode={ColorModeKeys.primary} count={usersOrders.info.totalItems} className="ml-2" />
                ) : null}
              </div>
            }
            size="default"
            scrollStrategy="cardBody"
            extra={
              <Switch
                loading={usersOrders.info.isLoading}
                label={t("common:driver.stuck") as string}
                checked={driverAdminFilterGapInHours != null}
                onChange={(value) => setDriverAdminFilterGapInHours(value ? 3 : null)}
              />
            }
          >
            {usersOrders.items?.length > 0 ? (
              <div className="d-stack-column spacing-2">
                {/*{statsData.map((item) => (*/}
                {/*  <React.Fragment key={item.title}>*/}
                {/*    <DriverStatisticCard digit={item.digit} title={item.title} subtitle={item.subtitle} />*/}
                {/*  </React.Fragment>*/}
                {/*))}*/}
                {usersOrders.items.map((u) => (
                  <Card
                    // hoverable
                    // isShadowed={false}
                    clickable
                    onClick={() => handleUserCardOpen(u.id as number)}
                    variant="secondary"
                  >
                    <div className="d-flex flex-nowrap align-center">
                      <Avatar size={36} color={u.color} src={u.avatar?.image?.url} text={[u?.lastName, u?.firstName]} />
                      <div className="flex-grow-1 d-stack-column mx-3" style={{ overflow: "hidden" }}>
                        <div className="d-stack-column align-start">
                          <Text
                            weight={500}
                            size="16px"
                            children={(u?.name ?? "").trim().length > 0 ? u?.name : u?.email}
                          />
                          <Text
                            children={`${t("common:driver.last_interaction")}: ${
                              u.dateControlSessionLastInteraction != null
                                ? dateHelpers.formatDate(u.dateControlSessionLastInteraction, {
                                    formatObject: { month: "short" },
                                  })
                                : t("common:driver.no_data")
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                <ScrollTrigger
                  onIntersection={() => usersOrders.loadNext()}
                  hidden={usersOrders.info.isDone}
                  marginTop={usersOrders.items.length > 0}
                />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </div>
      </PageContent>
    </>
  );
}

export default observer(DriverAdminHistoryPage);
