import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TagIcon } from "../../../constants/icon";
import { Tag } from "../dataDisplay/tags/tag/Tag";
import "./SlectTags.scss";
import { Select } from "../select/Select";
import uniq from "lodash/uniq";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { ColorPicker } from "../colorPicker/ColorPicker";
import { SelectTagsOption } from "./selectTagsOption/SelectTagsOption";
import { ISelectTagOption, ISelectTags } from "./ISelectTags";
import { getColor } from "../../../utils/hex2rgb";
import useDebounce from "../../../hooks/useDebounce";
import { api } from "../../../services";
import { useTranslation } from "react-i18next";

export const SelectTags = memo((props: ISelectTags) => {
  const { t } = useTranslation();

  const options: ISelectTagOption[] = useMemo(() => {
    return props.tags.map(({ id, color, name: label }) => ({
      id,
      value: label,
      label,
      color,
    }))
  }, [props.tags])


  const [showColorPicker, setShowColorPicker] = useState<string>("");
  const handleChangeShowColorPicker = useCallback((value: string) => {
    setShowColorPicker(value);
  }, []);

  const [renameTag, setRenameTag] = useState<string>("");
  const handleChangeRenameTag = useCallback((value: string) => {
    console.log("handleChangeRenameTag value", value);
    setRenameTag(value);
  }, []);

  const [selected, setSelected] = useState<ISelectTagOption[]>([]);

  const selectedList = useMemo(() => {
    return selected.map((item) => item.value)
  }, [selected]);

  useEffect(() => {
    const tagsList: ISelectTagOption[] = props.selectedTags.map(({ color, id, name }) => ({
      value: name,
      color,
      label: name,
      id,
    }));
    setSelected((prev) => {
      const newTags = tagsList.filter((tag) => !prev.find((item) => item.id === tag.id));
      if (newTags.length) {
        return [...prev, ...newTags]
      }
      return prev;
    });

  }, [props.selectedTags]);

  const debounceSelected = useDebounce(selected, 500);

  const [removedTagsIds, setRemovedTagsIds] = useState<number[]>([]);
  const debounceRemovedTagsIds = useDebounce(removedTagsIds, 500);

  useEffect(() => {
    console.log("debounceSelected", debounceSelected);
    console.log("removedTagsIds", debounceRemovedTagsIds);
    props.onChange({
      addedTags: debounceSelected,
      removedTags: debounceRemovedTagsIds,
    })
  }, [debounceSelected, debounceRemovedTagsIds]);

  const handleChangeColor = (tag: any) => {
    updateTag(tag);
    setShowColorPicker("");
    // setEditTag(null);
  };

  const updateTag = useCallback((tag: any) => {
    const editTagValue = renameTag;
    setSelected((prev) => {
      const arr = [...prev];
      const indexCurrentTag = arr.findIndex((item) => editTagValue
        ? item.value === editTagValue
        : item.value === tag.value
      );
      if (indexCurrentTag !== -1) {
        arr.splice(indexCurrentTag, 1, tag);
        return arr;
      }
      return prev;
    });
  }, [renameTag]);

  const handleCreateTag = useCallback(async (name: string) => {
    const tag = await api.tags.createTag({
      name,
    });
    if (tag) {
      props.refetchTags();
      const { name, color, id } = tag;
      setSelected((prev) => [...prev.filter((item) => item.value !== name), {
        value: name,
        color,
        label: name,
        id,
      }]);
    }
  }, []);

  const handleChangeTag = useCallback((tags: string[]) => {
    const arr = uniq(tags.map((item) => item.toLowerCase()));
    const tagsList: ISelectTagOption[] = arr.map((tag) => {
      const currentTag = options.find((option) => option.label.toLowerCase() === tag.toLowerCase());
      const { id, color, label, value } = currentTag ?? {};
      if (id && removedTagsIds.includes(id)) {
        setRemovedTagsIds((prev) => prev.filter((item) => item !== id));
      }
      if (!currentTag) {
        handleCreateTag(tag);
      }

      return {
        value: value ?? tag,
        color,
        label: label ?? tag,
        id,
      }
    });
    setSelected(tagsList);
  }, [removedTagsIds, options]);

  const handleRemoveTag = useCallback((tag: ISelectTagOption) => {
    setSelected((prev) => {
      return prev.filter((item) => tag.id ? item.id !== tag.id : item.value !== tag.value );
    });
    // const currentRemoveTag = options.find((item) => item.value !== tag);
    if (tag.id) {
      setRemovedTagsIds((prev) => ([...prev, tag.id!]));
    }
    // if (currentRemoveTag) {
    //   setRemovedTagsIds((prev) => ([...prev, currentRemoveTag.id!]));
    // }
  }, [options]);

  const tagRender = useCallback((option: any) => {
    const currentTag = selected.find((item) => item.value === option.value);

    return (
      <SelectTagsOption
        tag={currentTag}
        onRemoveTag={handleRemoveTag}
        showColorPicker={showColorPicker}
        renameTag={renameTag}
        updateTag={updateTag}
        onChangeRenameTag={handleChangeRenameTag}
        onChangeShowColorPicker={handleChangeShowColorPicker}
        onChangeColor={handleChangeColor}
      />
    )
  }, [selected, showColorPicker, renameTag]);

  const optionRender = useCallback((option) => {
    return (
      <Tag
        className="d-flex align-center justify-center mr-1"
        bordered={false}
        style={{
          height: "24px",
          borderColor: getColor(option.color ?? option.data?.color, 0.4),
          background: getColor(option.color ?? option.data?.color, 0.05),
          color: getColor(option.color ?? option.data?.color),
        }}
      >
        { option.label }
      </Tag>
    )
  }, []);

  return (
    <>
      {/*{isShowColorPicker && (*/}
      {/*  <ColorPicker*/}
      {/*    color={editTag?.color}*/}
      {/*    onChangeColor={handleChangeColor}*/}
      {/*  />*/}
      {/*)}*/}

      <Select
        mode="tags"
        style={{ width: '100%' }}
        placeholder={t("ui:placeholder.empty")}
        value={selectedList}
        onChange={handleChangeTag}
        options={options}
        tagRender={tagRender}
        suffixIcon={<TagIcon size={18}/>}
        optionRender={optionRender}
        className={"select-tags"}
        popupClassName={"select-tags-dropdown"}
        virtual={false}
        optionFilterProp={"value"}
        listHeight={94}
        filterOption={(input, option) => {
          return (option?.label ? `${option?.label}` : '').toLowerCase().includes(input.toLowerCase())
        }}
      />
    </>
  )
});