import React, { createRef, forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { IssueHistoryConst } from "../../../../../types/consts";
import { useTranslation } from "react-i18next";
import { IIssueHistorySenderBlock } from "./IssueHistorySenderBlock.interface";
import { IssueHistorySenderBlockView } from "./IssueHistorySenderBlockView";
import { IAudioRecorderRef } from "../../../../../../../uiKit/audioRecorder/AudioRecorder";
import { api } from "../../../../../../../../services";
import { TMediaRecorderStatus } from "../../../../../../../../hooks/useMediaRecorder";

export const IssueHistorySenderBlock = memo(
  forwardRef((props: IIssueHistorySenderBlock, ref): JSX.Element => {
    const { t } = useTranslation();
    const uploadFilesInputRef = createRef();
    const audioRecorderRef = useRef<IAudioRecorderRef>(null);
    const [audioMessageBlob, setAudioMessageBlob] = useState<Blob | null>(null);

    const [isFileLoaderUploading, setIsFileLoaderUploading] = useState<boolean>(false);
    const [loadingAcceptButton, setLoadingAcceptButton] = useState<boolean>(false);
    const [audioRecorderStatus, setAudioRecorderStatus] = useState<TMediaRecorderStatus>("idle");

    const isFileLoaderUploadingCopy = useRef(false);

    const editorRef = useRef<{ isEmptyEditor: () => boolean; resetEditor: () => void }>();

    const setIsFileLoaderUploadingSync = (v: boolean) => {
      setIsFileLoaderUploading(v);
      isFileLoaderUploadingCopy.current = v;
    };

    const sendPopupItems = [
      {
        id: 2,
        text: t("ui:button.send_comment"),
        action: () => props.onSendComment(IssueHistoryConst.Comment, ''),
      },
      {
        id: 1,
        text: t("ui:button.send_message"),
        action: () => props.onSendComment(IssueHistoryConst.Message, ''),
      },
    ];

    const handleAcceptComments = async () => {
      setLoadingAcceptButton(true);
      await props.onAcceptIssueHistory();
      setLoadingAcceptButton(false);
    };

    const getIsDisabled = (): boolean => {
      if (audioRecorderStatus == "recording") return true;
      if (audioMessageBlob != null) return false;
      if (!(props.allowedCommentsTypes && props.allowedCommentsTypes?.length > 0)) return true;
      // if (isFileLoaderUploading) return true;
      if (
        !isFileLoaderUploading &&
        !(
          props.attachments.filter((a) => a.url != null && a.url.length > 0).length == 0 ||
          props.attachments.filter((a) => a.url == null).length > 0
        )
      )
        return false;
      // if (props.commentIssue.length == 0 || isContentEmpty(editorRef.current?.isEmptyEditor()!)) return true;
      if (props.commentIssue.length == 0 || editorRef.current?.isEmptyEditor()!) return true;
      return false;
    };

    const uploadAudioMessage = async () => {
      if (audioMessageBlob == null) return;
      let formData = new FormData();
      formData.append("files", audioMessageBlob, `audioMessage-${new Date().getTime()}.wav`);
      const r = await api.staticFile.upload(formData, "audio");
      if (r == null) {
        return;
      }
      if (r[0] == null) return;
      props.setAttachments([...props.attachments, ...r]);
    };

    const handleSaveAudioMessage = async (blob: Blob | null) => {
      setAudioMessageBlob(blob);
      // if (blob == null) return;
      // let formData = new FormData();
      // formData.append("files", blob, `audioMessage-${new Date().getTime()}.wav`);
      // const r = await api.staticFile.upload(formData, "audio");
      // if (r == null) {
      //   console.log("error");
      //   return;
      // }
      // if (r[0] == null) return;
      // props.setAttachments([...props.attachments, ...r]);
    };

    const handleCommentSend = async (type: string, text?: string) => {
      await uploadAudioMessage();
      props.onSendComment?.(type, text);
      audioRecorderRef.current?.recordingReset();
    };

    useImperativeHandle(ref, () => ({
      isEmptyEditor: editorRef.current?.isEmptyEditor,
      resetEditor: editorRef.current?.resetEditor,
    }));

    return (
      <IssueHistorySenderBlockView
        ref={editorRef}
        issueId={props.issueId}
        isAllowAceptButton={props.isAllowAceptButton}
        onAcceptComments={props.onAcceptIssueHistory}
        loadingAcceptButton={loadingAcceptButton}
        audioRecorderRef={audioRecorderRef}
        allowedCommentsTypes={props.allowedCommentsTypes}
        commentIssue={props.commentIssue}
        onChangeComment={props.onChangeComment}
        isLoadingSend={props.isLoadingSend}
        onSendComment={(type: string, text?: string) => handleCommentSend(type, text)}
        attachments={props.attachments}
        setAttachments={props.setAttachments}
        onAcceptIssueHistory={handleAcceptComments}
        onDeleteAttachmentById={props.onDeleteAttachmentById}
        uploadFilesInputRef={uploadFilesInputRef}
        setIsFileLoaderUploadingSync={setIsFileLoaderUploadingSync}
        sendPopupItems={sendPopupItems}
        onAudioRecorderStatusChange={setAudioRecorderStatus}
        getIsDisabled={getIsDisabled}
        onSaveAudioMessage={handleSaveAudioMessage}
      />
    );
  })
);
