import React from "react";
import "./FileUploaderItem.scss";
import { FiTrash2 } from "@react-icons/all-files/fi/FiTrash2";
import { FiImage } from "@react-icons/all-files/fi/FiImage";
import { FiFile } from "@react-icons/all-files/fi/FiFile";
import { FiFileText } from "@react-icons/all-files/fi/FiFileText";
import { FiFilm } from "@react-icons/all-files/fi/FiFilm";
import { TFileUploader } from "../FileUploader";
import { FiDownload } from "@react-icons/all-files/fi/FiDownload";
import { IconButton, Spin, Text, Tooltip } from "../../../uiKit";

interface IFileUploaderItemView {
  value: TFileUploader;
  readonly?: boolean;
  onClick: (event: any) => void;
  onDownloadClick: (event: any) => void;
  onDeleteClick: (event: any) => void;
  smallSize?: boolean;
  noDelete?: boolean;
}

function GetIcon({ name, url, smallSize }: { name: string; url: string; smallSize?: boolean }) {
  const defaultIconSize = smallSize ? 20 : 35;

  const icons = [
    { name: "file", element: <FiFile size={defaultIconSize} /> },
    { name: "image", element: <FiImage size={defaultIconSize} /> },
    { name: "text", element: <FiFileText size={defaultIconSize} /> },
    { name: "video", element: <FiFilm size={defaultIconSize} /> },
  ];

  let iconType = "file";

  if (name.match(/.(jpg|jpeg|png|gif)$/i)) {
    iconType = "image";
  }

  if (name.match(/.(doc|txt)$/i)) {
    iconType = "text";
  }

  if (name.match(/.(mp4|webm|avi|mov|wmv)$/i)) {
    iconType = "video";
  }

  if (iconType === "image") {
    return (
      <img
        className={`file-uploader-item__preview-box-image ${
          smallSize && "file-uploader-item__preview-box-image-small"
        }`}
        src={url}
        alt="attachments"
      />
    );
  }

  return icons.find((i) => i.name == iconType)?.element ?? <FiFile />;
}

function getTrimmedFileName(name: string, smallSize?: boolean): string {
  const fileNameChuncks = name.split(".");
  const fileType = fileNameChuncks[fileNameChuncks.length - 1];
  let newName: string = "";
  fileNameChuncks?.forEach((item, index) => {
    if (index !== fileNameChuncks.length - 1) {
      newName += item;
    }
  });
  if (name.length > 0) {
    if (name.length <= 15) {
      newName = name;
    } else {
      newName = name.substring(0, smallSize ? 6 : 10) + "..." + fileType;
    }
  } else {
    newName = "File X";
  }
  return newName;

  // let str = "";
  // if (name.length > 0) {
  //   if (name.length <= 20) {
  //     str = name;
  //   } else {
  //     str = name.substring(0, 10) + "..." + name.slice(-10);
  //   }
  // } else {
  //   str = "File X";
  // }
  // return str;
}

function FileUploaderItemView(props: IFileUploaderItemView) {
  return (
    <div
      style={{
        cursor: (props.value.uploadedFile?.url ?? "").trim().length > 0 ? "pointer" : undefined,
      }}
      onClick={(event: any) => props.onClick(event)}
      className={`file-uploader-item__container ${
        props.smallSize && "file-uploader-item__container-small"
      } align-center`}
    >
      {props.value.uploadedFile != null &&
        props.value.uploadedFile?.url != null &&
        props.value.uploadedFile.url.length > 0 &&
        !props.readonly && (
          <div className="d-stack spacing-1 file-uploader-item__button__wrapper">
            <IconButton
              onClick={(event: any) => props.onDownloadClick(event)}
              className="file-uploader-item__button--download"
              size="small"
            >
              <FiDownload />
            </IconButton>
            {!!!props.noDelete && (
              <IconButton
                onClick={(event: any) => props.onDeleteClick(event)}
                className="file-uploader-item__button--delete"
                size="small"
              >
                <FiTrash2 />
              </IconButton>
            )}
          </div>
        )}
      <div
        className={`file-uploader-item__preview-box ${
          props.smallSize && "file-uploader-item__preview-box-small"
        } d-flex align-center`}
      >
        {(props.value.progress != 100 || props.value.uploadedFile.url == null) && (
          <Spin /*size={!!props.smallSize ? 20 : 35}*/ />
        )}
        {!(props.value.progress != 100 || props.value.uploadedFile.url == null) && (
          <GetIcon
            name={props.value.uploadedFile?.fileName ?? ""}
            smallSize={!!props.smallSize}
            url={props.value.uploadedFile.url ?? ""}
          />
        )}
      </div>
      <Tooltip
        title={
          (props.value.uploadedFile?.fileName ?? "").trim().length >= 25 ? props.value.uploadedFile?.fileName ?? "" : ""
        }
      >
        <Text className="file-uploader-item__name flex-grow-1">
          {getTrimmedFileName(props.value.uploadedFile?.fileName ?? "", props.smallSize)}
        </Text>
      </Tooltip>
      <div style={{ width: props.value.progress + "%" }} className="file-uploader-item__progress" />
    </div>
  );
}

export default FileUploaderItemView;
