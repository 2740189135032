import React from "react";
import { MdTagFaces } from "@react-icons/all-files/md/MdTagFaces";
import { Icon, IconButton } from "../../../uiKit";
import { IAddReactionButton } from "../interfaces/Reactions.interface";
import { SmileIcon } from "../../../../constants/icon";
import "./AddReactionButton.scss";

export const AddReactionButton: React.FC<IAddReactionButton> = ({ onClick, disabled, iconSize, isNewStyle, isBorder}) => {
  return (
    <div className={`reaction-button ${isBorder ? 'reaction-button_border' : ''} ${
      isNewStyle ? 'reaction-button_new-style' : ''
    }`}>
      <IconButton
        onClick={onClick}
        // type="text"
        disabled={disabled}
        icon={
          <Icon
            component={() => (
              isNewStyle
                ? <SmileIcon/>
                : <MdTagFaces fontSize={iconSize ?? 22} />
            )}
          />
        }
      />
    </div>
  );
};
