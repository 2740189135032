import React from "react";
import { IUserReactionCard } from "../interfaces/Reactions.interface";
import { UsersTooltip } from "./UsersTooltip";

export const UserReactionCard: React.FC<IUserReactionCard> = (props) => {
  const backgroundColor = props.isNewStyle
    ? "var(--color-primary-base)" : "var(--color-layout-fill-tertiary)";


  return (
    <UsersTooltip users={props.reactionChip.userNames}>
      <div
        style={{
          backgroundColor: props.disabled ? "var(--color-layout-fill-quaternary)" : backgroundColor,
          cursor: props.disabled ? "not-allowed" : "default",
        }}
        onClick={() => !props.disabled && props.onClickByReaction(props.reactionChip.emoji)}
        className={`d-stack-row justify-space-between spacing-2 align-center user-reaction-chip ma-1 ${
          props.isNewStyle ? "user-reaction-chip_new-style" : ""
        }`}
      >
        <span
          className={`reaction-symbol ${props.isNewStyle ? "reaction-symbol_new-style" : ""}`}
        >
          {props.reactionChip.emoji}
        </span>
        <span>{props.reactionChip.usersCount}</span>
      </div>
    </UsersTooltip>
  );
};
