import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { FaRegAddressCard } from "@react-icons/all-files/fa/FaRegAddressCard";
import { useNavigate } from "react-router-dom";
import "./DriverPrePage.scss";
import "./DriverAdminStatisticsPage";
import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Empty, Icon, Switch, Text } from "../../uiKit";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { useDateHelpers } from "../../../hooks";
import { ConfigKeys, IFiltersContainerParams } from "./components/filtersContainer/FiltersContainer.interface";
import { FiltersContainer } from "./components/filtersContainer/FiltersContainer";
import { CounterChip } from "../../elements/counterChip/CounterChip";
import { ColorModeKeys } from "../../elements/counterChip/misc/keys";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";

function DriverPrePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dateHelpers = useDateHelpers();

  const [driverAdminFilterGapInHours, setDriverAdminFilterGapInHours] = useState<number | undefined>(undefined);
  const [pageFilters, setPageFilters] = useState<IFiltersContainerParams>({
    phoneNumber: "",
    userName: undefined,
    period: undefined,
  });

  const initialFetch = () => {
    usersHistory.reset();
    usersOrders.reset();
    usersHistory.restart();
    usersOrders.restart();
  };

  const usersOrders = usePagingWithController(
    api.userShort,
    {
      ignoreContext: true,
      driverAdminFilter: "order",
      orderBy: "control_session_date",
      order: "asc",
      phoneNumber: (pageFilters.phoneNumber ?? "").trim().length > 0 ? pageFilters.phoneNumber : null,
      driverAdminFilterGapInHours: driverAdminFilterGapInHours,
      name: pageFilters.userName,
    },
    { pageSize: 40 }
  );
  const usersHistory = usePagingWithController(
    api.userShort,
    {
      ignoreContext: true,
      driverAdminFilter: "history",
      phoneNumber: (pageFilters.phoneNumber ?? "").trim().length > 0 ? pageFilters.phoneNumber : null,
      orderBy: "control_session_date",
      driverAdminFilterDateFrom: pageFilters.period?.from,
      driverAdminFilterDateTo: pageFilters.period?.to,
      name: pageFilters.userName,
    },
    { pageSize: 40 }
  );

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleUserCardOpen = (userId: number) => openInNewTab("/driver/user/" + userId);

  useEffect(() => {
    usersOrders.reset();
    usersOrders.restart();
  }, [driverAdminFilterGapInHours]);

  useEffect(() => {
    initialFetch();
  }, []);

  return (
    <>
      <PageHeader title={t("common:page_title.driver_pre")}>
        <div className="d-stack-row justify-space-between align-center full-width">
          <div className="d-stack-row align-center">
            <FiltersContainer
              params={pageFilters}
              isLoading={usersOrders.info.isLoading || usersHistory.info.isLoading}
              onRefresh={initialFetch}
              config={[ConfigKeys.userName, ConfigKeys.period, ConfigKeys.phoneNumber]}
              onChange={setPageFilters}
            />
          </div>
          <div>
            <Button
              icon={<Icon component={() => <FaRegAddressCard />} />}
              style={{ marginLeft: "auto" }}
              onClick={() => navigate("/driver/next")}
            >
              {t("ui:button.go_to_processing")}
            </Button>
          </div>
        </div>
      </PageHeader>
      <PageContent useTranslationInTabs isLoading={usersOrders.info.isLoading || usersHistory.info.isLoading}>
        <div className="d-stack-row full-height">
          <Card
            size="default"
            className="mr-1 flex-grow-1 flex-shrink-1"
            style={{ flexBasis: "50%" }}
            scrollStrategy="cardBody"
            title={
              <div className="d-flex align-center">
                {t("common:driver.history")}
                {usersHistory.info.totalItems ? (
                  <CounterChip
                    colorMode={ColorModeKeys.primary}
                    count={usersHistory.info.totalItems}
                    className="ml-2"
                  />
                ) : null}
              </div>
            }
          >
            {usersHistory.items?.length > 0 ? (
              <div className="d-stack-column spacing-2">
                {usersHistory.items.map((u) => (
                  <Card
                    // isShadowed={false}
                    clickable
                    onClick={() => handleUserCardOpen(u.id as number)}
                    variant="secondary"
                  >
                    <div className="d-flex flex-nowrap align-center">
                      <Avatar size={36} color={u.color} src={u.avatar?.image?.url} text={[u?.lastName, u?.firstName]} />
                      <div className="flex-grow-1 d-stack-column mx-3" style={{ overflow: "hidden" }}>
                        <div className="d-stack-column align-start">
                          <Text
                            weight={500}
                            size="16px"
                            children={(u?.name ?? "").trim().length > 0 ? u?.name : u?.email}
                          />
                          <Text
                            children={`${t("common:driver.last_interaction")}: ${
                              u.dateControlSessionLastInteraction != null
                                ? dateHelpers.formatDate(u.dateControlSessionLastInteraction, {
                                    formatObject: { month: "short" },
                                  })
                                : t("common:driver.no_data")
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                <ScrollTrigger
                  onIntersection={() => usersHistory.loadNext()}
                  hidden={usersHistory.info.isDone}
                  marginTop={usersHistory.items.length > 0}
                />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
          <Card
            size="default"
            className="ml-1 flex-grow-1 flex-shrink-1"
            style={{ flexBasis: "50%" }}
            scrollStrategy="cardBody"
            title={
              <div className="d-flex align-center">
                {t("common:driver.in_queue")}
                {usersOrders.info.totalItems ? (
                  <CounterChip colorMode={ColorModeKeys.primary} count={usersOrders.info.totalItems} className="ml-2" />
                ) : null}
              </div>
            }
            extra={
              <Switch
                label={t("common:driver.stuck") as string}
                checked={driverAdminFilterGapInHours != null}
                loading={usersOrders.info.isLoading}
                onChange={(value) => {
                  value ? setDriverAdminFilterGapInHours(3) : setDriverAdminFilterGapInHours(undefined);
                }}
              />
            }
          >
            {usersOrders.items?.length > 0 ? (
              <div className="d-stack-column spacing-2">
                {usersOrders.items.map((u) => (
                  <Card clickable onClick={() => handleUserCardOpen(u.id as number)} variant="secondary">
                    <div className="d-flex flex-nowrap align-center">
                      <Avatar size={36} color={u.color} src={u.avatar?.image?.url} text={[u?.lastName, u?.firstName]} />
                      <div className="flex-grow-1 d-stack-column mx-3" style={{ overflow: "hidden" }}>
                        <div className="d-stack-column align-start">
                          <Text
                            weight={500}
                            size="16px"
                            children={(u?.name ?? "").trim().length > 0 ? u?.name : u?.email}
                          />
                          <Text
                            children={`${t("common:driver.last_interaction")}: ${
                              u.dateControlSessionLastInteraction != null
                                ? dateHelpers.formatDate(u.dateControlSessionLastInteraction, {
                                    formatObject: { month: "short" },
                                  })
                                : t("common:driver.no_data")
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                <ScrollTrigger
                  onIntersection={() => usersOrders.loadNext()}
                  hidden={usersOrders.info.isDone}
                  marginTop={usersOrders.items.length > 0}
                />
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </div>
      </PageContent>
    </>
  );
}

export default DriverPrePage;
