import React from "react";
import { useTranslation } from "react-i18next";
import { useDateHelpers } from "../../../../hooks";
import { IHintDialogView } from "./HintDialog.interface";
import { Button, Dialog, DialogActions, Table } from "../../../uiKit";
import { ColumnsType } from "antd/lib/table";
import ScrollTrigger from "../../../service/scrollTrigger/ScrollTrigger";

interface DataType {
  name: string | null;
  description: string | null;
  dateCreated: string;
  datePostpone: string | null;
}

export const HintDialogView = (props: IHintDialogView) => {
  const { t } = useTranslation();
  const dateHelpers = useDateHelpers();

  const columns: ColumnsType<DataType> = [
    { title: t("parse:action"), dataIndex: "name", key: "name" },
    { title: t("parse:result"), dataIndex: "description", key: "description" },
    { title: t("parse:date_of_action"), dataIndex: "dateCreated", key: "dateCreated" },
    { title: t("parse:will_solve_up_to"), dataIndex: "datePostpone", key: "datePostpone" },
  ];

  return (
    <Dialog
      zIndex={1203}
      width="80%"
      title={t("ui:title.driver_issue_report")}
      open={props.open}
      onClose={props.onClose}
      destroyOnClose
      closable={false}
    >
      <Table
        loading={props.isLoading}
        columns={columns}
        dataSource={props.reports?.map((item) => ({
          ...item,
          dateCreated: item.dateCreated
            ? dateHelpers.formatDate(item.dateCreated ?? "", { formatObject: { month: "short" } })
            : "",
          datePostpone: item.datePostpone
            ? dateHelpers.formatDate(item.datePostpone ?? "", { formatObject: { month: "short" } })
            : "",
        }))}
        pagination={false}
      />
      <ScrollTrigger
        disabled={props.isDone}
        onIntersection={props.loadNext}
        marginTop={(props.reports?.length ?? 0) > 0}
        hidden={props.isDone}
      />
      <DialogActions>
        <Button onClick={props.onClose} variant="text">
          {t("ui:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
