import React from "react";
import { IssueCustomFieldDto } from "../../../../api";
import { useTranslation } from "react-i18next";
import { guideKeys } from "../../../../stores/hintStore";
// import { OpeningGuideIcon } from "../../../elements/openingGuideIcon/OpeningGuideIcon";
import { Switch } from "../../../uiKit";
import { OpeningGuideIcon } from "../../../elements/openingGuideIcon/OpeningGuideIcon";
import { SwitchFieldType } from "../types/consts";

interface IIssueCustomFieldsView {
  data: IssueCustomFieldDto[];
  elementsState?: { key: string; disabled?: boolean; initialValue?: any }[];
  showHintIcons?: boolean;
  handleChange: (key: string, value: any, id: number | null) => void;
  fieldKey?: SwitchFieldType;
}

function IssueCustomFieldsFormView(props: IIssueCustomFieldsView) {
  const { t } = useTranslation();
  return (
    <div className={`d-stack-column ${props.fieldKey ? undefined : "spacing-2"}`}>
      {props.data.map((field) => (
        <div key={field.key} className="d-flex d-stack-row justify-start align-center">
          {props.fieldKey && props.fieldKey === field.key && (
            <Switch
              useAsPureComponent={true}
              label={field.name ?? t("ui:label.issue_custom_filed." + (field?.key ?? "")).toString()}
              disabled={props.elementsState?.find((el) => el.key == field.key)?.disabled ?? undefined}
              checked={field.valueBool ?? false}
              onChange={(newValue) => props.handleChange(field.key as string, newValue, field.id ?? null)}
            />
          )}
          {!props.fieldKey && (
            <Switch
              useAsPureComponent={true}
              label={field.name ?? t("ui:label.issue_custom_filed." + (field?.key ?? "")).toString()}
              disabled={props.elementsState?.find((el) => el.key == field.key)?.disabled ?? undefined}
              checked={field.valueBool ?? false}
              onChange={(newValue) => props.handleChange(field.key as string, newValue, field.id ?? null)}
            />
          )}
          {props.showHintIcons && (
            <OpeningGuideIcon
              ml={8}
              contentKey={guideKeys.recurringIssues}
              anchorId={field.key === "is_strict_deadline" ? "5anchor" : "6anchor"}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default IssueCustomFieldsFormView;
