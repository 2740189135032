import React, { useEffect, useMemo } from "react";
import { ICommentCard } from "./History.interface";
import { HistoryDto, HistoryReactionDto } from "../../../api";
import { api } from "../../../services";
import { CommentCard } from "../../elements/commentCard/CommentCard";
import { ReactionsStrategy } from "../../elements/emojiPicker/strategies/ReactionsStrategy";

const HistoryCardMemo: React.FC<ICommentCard> = (props) => {
  const regulationReactionsStrategy = new ReactionsStrategy<HistoryDto>(
    props.historyId,
    api.regulationHistory,
    (x: HistoryReactionDto) => x.value as string,
    (x: HistoryReactionDto) => x?.user?.name ?? x?.user?.nameFallback ?? ""
  );

  const dateFormat = useMemo(() => {
    const date = new Date(props.dateCreated);
    const currentYear = new Date().getFullYear();
    return currentYear === date.getFullYear() ? 'DD MMM HH:mm' : 'DD MMM YYYY HH:mm';
  }, [props.dateCreated]);


  return (
    <CommentCard
      creator={props.creator}
      index={props.index}
      historyId={props.historyId}
      reactions={props.reactions}
      reactionStrategy={regulationReactionsStrategy}
      commentContent={props.commentContent}
      commentAttachments={props.commentAttachments}
      dateCreated={props.dateCreated}
      creatorId={props.creatorId}
      isEditing={props.isEditing}
      editCommentId={props.editCommentId}
      handleEditClick={props.handleEditClick}
      handleSaveClick={props.handleSaveClick}
      handleRemoveComment={props.handleRemoveComment}
      item={props.item}
      isNewStyle={true}
      format={dateFormat}

    />
  );
};

export const HistoryCard = React.memo(
  HistoryCardMemo,
  (x, y) =>
    x?.historyId === y?.historyId &&
    x?.editCommentId === y?.editCommentId &&
    x?.commentContent === y?.commentContent
);
