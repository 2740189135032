import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./CommunicationPage.scss";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { LocalStorageHelpers, useApi, useLocalStorage, useRootStore } from "../../../hooks";
import { CreateIssueButton } from "../../modules/pages/communications/components/createIssueButton/CreateIssueButton";
import { sortKeys, sortKeyType, sortValues } from "./misc/consts";
import VideoDialog from "../../modules/dialogs/videoDialog/VideoDialog";
import { RelationsTypes } from "../../modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch.interface";
import { CommunicationModeKey, RelationsKeys } from "../../modules/pages/communications/constants/keys";
import { BoardFilterContext, IBoardColumnsState } from "../../../contexts/communication/boardFilterContext";
import { IDropdownItem } from "../../uiKit/navigation/dropdown/Dropdown";
import { SortButton } from "../../elements/sortButton/SortButton";
import { valueType } from "../../elements/sortButton/SortButton.interface";
import { FilterButton } from "../../elements/filterButton/FilterButton";
import { FilterDropdownContent } from "../../modules/pages/communications/components/filterDropdownContent/FilterDropdownContent";
import ZoomButton from "../../elements/zoomButton/ZoomButton";
import { ArchiveButton } from "../../modules/pages/communications/components/archiveButton/ArchiveButton";
import { CommunicationModeSwitch } from "../../modules/pages/communications/components/communicationModeSwitch/CommunicationModeSwitch";
import { BoardRelationSwitch } from "../../modules/pages/communications/components/boardRelationSwitch/BoardRelationSwitch";
import { CommunicationHeaderContentCalendarPlanning } from "../../modules/pages/communications/components/communicationHeaderContent/CommunicationHeaderContentCalendarPlanning";
import { BoardCollapse } from "../../modules/pages/communications/components/boardCollapse/BoardCollapse";
import { DragAndDropContainer } from "../../modules/pages/communications/components/boardCollapse/DragAndDropContainer";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import { CommunicationPageContentCalendarPlanning } from "./CommunicationPageContentCalendarPlanning";
import {
  CalendarFilterDropdownContent
} from "../../modules/pages/communications/components/calendarFilterDropdownContent/CalendarFilterDropdownContent";
import { api } from "../../../services";
import { DownloadButton } from "../../elements/DownloadButton/DownloadButton";
import PlanningPage from "../planning/PlanningPage";
import dayjs from "dayjs";
import {
  regeneratorFilters
} from "../../modules/pages/communications/common/regeneratorFilters";
import { BroadcastChannel } from "broadcast-channel";
import { SubordinatesList } from "../../modules/pages/communications/components/subordinatesList/SubordinatesList";
import useDebounce from "../../../hooks/useDebounce";
import { isEqual } from "lodash";
import { usePagingWithController } from "../../../hooks/usePaging";
import { useLocation } from "react-router-dom";

function CommunicationPageViewObserver() {
  const { t } = useTranslation();
  const {
    authStore,
    boardStore,
    communicationStore,
    communicationCalendarPlanningStore,
    orgchartStore,
  } = useRootStore();

  const location = useLocation();

  const [isShowCommunicationModeSwitch, setIsShowCommunicationModeSwitch] = useState<boolean>(false);

  useEffect(() => {
    const { companyModules = [] } = authStore.getInitialInfo ?? {};
    const isShowCalendarPlaningModule = !!companyModules?.find(({type}) => type === 11) ?? false;
    const isShowTablePlaningModule = !!companyModules?.find(({type}) => type === 10) ?? false;
    const mode = communicationStore.getMode;
    setIsShowCommunicationModeSwitch(isShowCalendarPlaningModule || isShowTablePlaningModule);
    if (!isShowCalendarPlaningModule && !isShowTablePlaningModule
      || !isShowCalendarPlaningModule && mode === CommunicationModeKey.CalendarPlanning
      || !isShowTablePlaningModule && mode === CommunicationModeKey.TablePlanning
    ) {
      communicationStore.setMode(CommunicationModeKey.Kanban);
    }
  }, [authStore]);

  // const [isEditorDialogShow, setIsEditorDialogShow] = useLocalStorage<boolean>("communicationsTextEditorDialog", true);

  // const handleClose = useCallback(() => {
  //   setIsEditorDialogShow(false);
  // }, [setIsEditorDialogShow]);

  const [zoom, setZoom] = useLocalStorage<number>("zoomCommunication", 100);

  const sortMenuValues: IDropdownItem[] = [
    { key: 1, text: t("common:misc.sort_items.ones_first"), onClick: () => handleChangeSortOptions(sortKeys.date) },
    {
      key: 2,
      text: t("common:misc.sort_items.old_ones"),
      onClick: () => handleChangeSortOptions(sortKeys.date_reverse)
    },
    {
      key: 3,
      text: t("common:misc.sort_items.close_deadline"),
      onClick: () => handleChangeSortOptions(sortKeys.date_deadline)
    },
    {
      key: 4,
      text: t("common:misc.sort_items.high_priority"),
      onClick: () => handleChangeSortOptions(sortKeys.high_priority)
    },
    {
      key: 5,
      text: t("common:misc.sort_items.low_priority"),
      onClick: () => handleChangeSortOptions(sortKeys.low_priority)
    }
  ];

  const [userRelationFilter, setUserRelationFilter] = useState<RelationsTypes>(RelationsKeys.My);
  const [sortAndFilterSaved, setSortAndFilterSaved] = useLocalStorage<
    {
      key: RelationsTypes;
      companyId?: number | null;
      sortValue?: sortKeyType;
      filterState?: {
        senderId?: number;
        executorId?: number;
        // executorId1?: number[];
        hasViolation?: boolean;
        flowType?: number;
        isGroupingByOrgcharts?: boolean;
        isGroupingByUsers?: boolean;
        isDeadlineToday?: boolean;
        scheduledToday?: boolean;
        tags?: number[];
      };
    }[]
  >("sortAndFilterBackup", []);

  const prevSortAndFilter = useRef(sortAndFilterSaved);

  const [sortAndFilter, setSortAndFilter] = useState<
    {
      key: RelationsTypes;
      companyId?: number | null;
      sortValue?: sortKeyType;
      filterState?: {
        senderId?: number;
        executorId?: number;
        // executorId1?: number[];
        hasViolation?: boolean;
        flowType?: number;
        isGroupingByOrgcharts?: boolean;
        isGroupingByUsers?: boolean;
        isDeadlineToday?: boolean;
        scheduledToday?: boolean;
        tags?: number[];
      };
    }[]
  >(sortAndFilterSaved);

  useEffect(() => {
    if (!isEqual(sortAndFilterSaved, prevSortAndFilter.current)) {
      prevSortAndFilter.current = sortAndFilterSaved;
      setSortAndFilter(sortAndFilterSaved);
    }
  }, [sortAndFilterSaved]);

  const [boardColumnsState, setBoardColumnsState] = useState<IBoardColumnsState[]>([]);
  // const [isGroupingByOrgcharts, setIsGroupingByOrgcharts] = useState<boolean>(false);
  const [isGroupingByOrgcharts, setIsGroupingByOrgcharts] = useLocalStorage<boolean>(
    "grouping-issues-by-orgcharts",
    true
  );

  const [isGroupingByUsers, setIsGroupingByUsers] = useLocalStorage<boolean>(
    "grouping-issues-by-users",
    false
  );


  const [isGroupingByTags, setIsGroupingByTags] = useLocalStorage<boolean>(
    "grouping-issues-by-tags",
    false
  );

  const board = boardStore.getBoard;

  const handleChangeUserRelation = (relation: RelationsTypes) => {
    setUserRelationFilter(relation);
  };
  // const handleChangeSenderIdFilter = (id?: number) => {
  //   if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
  //     setSortAndFilterSaved([
  //       ...sortAndFilter.map((v) =>
  //         v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
  //           ? { ...v, filterState: { ...(v.filterState ?? {}), senderId: id } }
  //           : v
  //       )
  //     ]);
  //   } else {
  //     setSortAndFilterSaved([
  //       ...sortAndFilter,
  //       { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { senderId: id } }
  //     ]);
  //   }
  // };

  const handleChangeSenderIdFilter = (id?: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved((prev) => ([
        ...prev.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), senderId: id } }
            : v
        )
      ]));
    } else {
      setSortAndFilterSaved((prev) => ([
        ...prev,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { senderId: id } }
      ]));
    }
  };

  // const handleChangeExecutorIdFilter = (id?: number) => {
  //   if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
  //     setSortAndFilterSaved([
  //       ...sortAndFilter.map((v) =>
  //         v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
  //           ? { ...v, filterState: { ...(v.filterState ?? {}), executorId: id } }
  //           : v
  //       )
  //     ]);
  //   } else {
  //     setSortAndFilterSaved([
  //       ...sortAndFilter,
  //       { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { executorId: id } }
  //     ]);
  //   }
  // };

  const handleChangeExecutorIdFilter = (id?: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved((prev) => ([
        ...prev.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), executorId: id } }
            : v
        )
      ]));
    } else {
      setSortAndFilterSaved((prev) => ([
        ...prev,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { executorId: id } }
      ]));
    }
  };

  const handleChangeHasViolationFilter = (value: boolean) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved([
        ...sortAndFilter.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), hasViolation: value } }
            : v
        )
      ]);
    } else {
      setSortAndFilterSaved((prev) => ([
        ...prev,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { hasViolation: value } }
      ]));
    }
  };

  const handleChangeScheduledTodayFilter = (value: boolean) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved((prev) => ([
        ...prev.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? { ...v, filterState: { ...(v.filterState ?? {}), scheduledToday: value, isDeadlineToday: false } }
            : v
        )
      ]));
    } else {
      setSortAndFilterSaved((prev) => ([
          ...prev,
          { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: {
            scheduledToday: value,
            isDeadlineToday: false
          } }
        ]));
    }
  };


  // const [filterTags, setFilterTags] = useState<number[]>([]);

  // const handleSelectedTags = useCallback((tags: number[]) => {
  //   setFilterTags(tags);
  //   // if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
  //   //   setSortAndFilterSaved([
  //   //     ...sortAndFilter.map((v) =>
  //   //       v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
  //   //         ? { ...v, filterState: { ...(v.filterState ?? {}), tags } }
  //   //         : v
  //   //     )
  //   //   ]);
  //   // } else {
  //   //   setSortAndFilterSaved([
  //   //     ...sortAndFilter,
  //   //     { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { tags } }
  //   //   ]);
  //   // }
  // }, []);

  // const filterTagsArray = useMemo(() => {
  //   const tags = sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
  //     ?.filterState?.tags ?? [];
  //   return tags;
  // }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const [filterTagsArray, setFilterTagsArray] = useLocalStorage<any>(
    "tags-filter",
    {}
  );
  const emptyTagsArray = useMemo(() => {
    return [];
  }, []);

  const filterTags = useMemo(() => {
    return filterTagsArray[userRelationFilter] ?? emptyTagsArray
  }, [filterTagsArray, userRelationFilter]);


  // const filterTags = useDebounce(filterList, 700);
  // console.log("filterTags", filterTags);

  const handleSelectedTags = useCallback((tags: number[]) => {
    console.log("tags", tags);
    setFilterTagsArray((prev: any) => ({
      ...prev,
      [userRelationFilter]: tags,
    }));
  }, [userRelationFilter]);

  // const handleSelectedTags = useCallback((tags: number[]) => {
  //   setFilterTagsArray((prev: any) => ({
  //     ...prev,
  //       [userRelationFilter]: tags,
  //   }));
  //   // if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
  //   //   setSortAndFilterSaved((prev) => ([
  //   //     ...prev.map((v) =>
  //   //       v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
  //   //         ? { ...v, filterState: { ...(v.filterState ?? {}), tags } }
  //   //         : v
  //   //     )
  //   //   ]));
  //   // } else {
  //   //   setSortAndFilterSaved((prev) => ([
  //   //     ...prev,
  //   //     { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { tags } }
  //   //   ]));
  //   // }
  // }, [userRelationFilter]);

  const handleChangeForTodayTasksFilter = useCallback(
    (value: boolean) => {
      if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
        setSortAndFilterSaved((prev) => [
          ...prev.map((v) =>
            v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
              ? {
                ...v,
                filterState: {
                  ...(v.filterState ?? {}),
                  isDeadlineToday: value,
                  scheduledToday: false,
                }
              }
              : v
          )
        ]);
      } else {
        setSortAndFilterSaved((prev) => [
          ...prev,
          {
            key: userRelationFilter,
            companyId: authStore.getCurrentCompanyId,
            filterState: { isDeadlineToday: value, scheduledToday: false, }
          }
        ]);
      }
    },
    [authStore.getCurrentCompanyId, sortAndFilter, userRelationFilter]
  );

  const handleChangeFlowTypeFilter = (value: number) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved((prev) => ([
        ...prev.map((v) =>
          v.key == userRelationFilter ? { ...v, filterState: { ...(v.filterState ?? {}), flowType: value } } : v
        )
      ]));
    } else {
      setSortAndFilterSaved((prev) => ([
        ...prev,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, filterState: { flowType: value } }
      ]));
    }
  };
  const handleResetFilters = () => {
    handleChangeSenderIdFilter();
    handleChangeExecutorIdFilter();
    handleChangeHasViolationFilter(false);
    handleChangeScheduledTodayFilter(false);

  };
  // @ts-ignore
  const handleChangeBoardColumnsState = useCallback((newValue: IBoardColumnsState) =>
    setBoardColumnsState((prev) =>
      prev.some((item) => item.boardColumnId == newValue.boardColumnId)
        ? [...prev].map((item) => (item.boardColumnId == newValue.boardColumnId ? newValue : item))
        : [...prev, newValue]
    ), []);

  const handleChangeSortOptions = (sortKey: sortKeyType) => {
    if (sortAndFilter.some((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)) {
      setSortAndFilterSaved((prev) => [
        ...prev.map((v) =>
          v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
            ? {
              ...v,
              sortValue: sortKey
            }
            : v
        )
      ]);
    } else {
      setSortAndFilterSaved((prev) => [
        ...prev,
        { key: userRelationFilter, companyId: authStore.getCurrentCompanyId, sortValue: sortKey }
      ]);
    }
  };

  const isLoading = useMemo(() => boardColumnsState.some((item) => item.isLoading), [boardColumnsState]);

  const handleChangeIsGroupingByOrgcharts = useCallback(
    (value: boolean) => {
      setIsGroupingByOrgcharts(value);
      if (value) {
        setIsGroupingByTags(false);
        setIsGroupingByUsers(false);
      }
    },
    [setIsGroupingByOrgcharts]
  );

  const handleChangeIsGroupingByUsers = useCallback(
    (value: boolean) => {
      setIsGroupingByUsers(value);
      if (value) {
        setIsGroupingByTags(false);
        setIsGroupingByOrgcharts(false);
      }
    },
    []
  );


  const handleChangeIsGroupingByTags = useCallback((value: boolean) => {
      setIsGroupingByTags(value);
      if (value) {
        setIsGroupingByOrgcharts(false);
        setIsGroupingByUsers(false);
      }
  },[]);

  useEffect(() => {
    LocalStorageHelpers.set("communicationTableZoom", zoom);
  }, [zoom]);

  const [calendarSubordinatesFilter, setCalendarSubordinatesFilter] = useLocalStorage<number[]>(
    "calendar-subordinates-filter",
    []
  );

  // const [calendarSubordinatesFilter, setCalendarSubordinatesFilter] = useState<number[]>([]);
  const handleChangeCalendarSubordinatesFilter = useCallback((id: number) => {
    handleChangeCalendarRoleId(undefined);
    setCalendarSubordinatesFilter((prev) => prev.includes(id)
      ? []
      : [id]
    );
  }, []);

  const handleResetCalendarSubordinatesFilter = useCallback(() => {
    setCalendarSubordinatesFilter([]);
  }, []);

  const usersRoles = useApi(
    () =>
      api.role.getAll({
        pageSize: -1,
        // userIds: communicationStore.getMode === CommunicationModeKey.CalendarPlanning
        //   ? calendarSubordinatesFilter
        //   : subordinatesFilter,
        userIds: calendarSubordinatesFilter,
        includeActiveUsers: true,
      }),
    null,
    () => {},
    {},
    false
  );

  useEffect(() => {
    usersRoles.fetch();
  }, [calendarSubordinatesFilter]);

  const usersRolesList = useMemo(() => {
    return usersRoles.value?.items.map(({ id, name }) => ({
      id,
      text: name ?? "",
      value: id,
    })) ?? [];
  }, [usersRoles.value?.items]);

  const rolesList = useMemo(() => {
    return (authStore.getInitialInfo?.identity?.roles ?? []).map((item) => ({
      id: item.roleId,
      text: item.role?.name ?? "",
      value: item.roleId,
    }));
  }, [authStore.getInitialInfo?.identity]);

  const [calendarRoleId, setCalendarRoleId] = useState<number | undefined>(undefined);
  const handleChangeCalendarRoleId = useCallback((roleId: number | undefined) => {
    setCalendarRoleId(roleId);
  }, []);

  const handleClickDownloadReport = useCallback(async () => {
    const dateFrom = communicationCalendarPlanningStore.getDateFrom;
    const dateTo = communicationCalendarPlanningStore.getDateTo;
    const data = await api.calendarEvent.getReport({dateFrom, dateTo, functionId: calendarRoleId});
    const href = URL.createObjectURL(data);
    const fileName = `${href.split("/").pop()}.csv`;
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    link.click();
  }, [communicationCalendarPlanningStore.getDateFrom, communicationCalendarPlanningStore.getDateTo, calendarRoleId]);

  // const filterTags = useMemo(() => {
  //   return sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
  //     ?.filterState?.tags ?? []
  // }, [sortAndFilter, authStore.getCurrentCompanyId]);

  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const handleChangeIsOpenFilter = useCallback((value: boolean) => {
    setIsOpenFilter(value);
  }, []);

  const isDeadlineToday = useMemo(() => {
    return sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.isDeadlineToday ?? false
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const scheduledToday = useMemo(() => {
    return   sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.scheduledToday ?? false;
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const hasViolation = useMemo(() => {
    return  sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.hasViolation ?? false;
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const senderId = useMemo(() => {
    const senderIdIdValue = sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.senderId ?? undefined;
    return senderIdIdValue;
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const executorId = useMemo(() => {
    const executorIdValue = sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.executorId ?? undefined;
    return executorIdValue;
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);


  const flowType = useMemo(() => {
    return sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
      ?.filterState?.flowType ?? 3;
  }, [sortAndFilter, userRelationFilter, authStore.getCurrentCompanyId]);

  const filterGenerator = useMemo(() => {
    return regeneratorFilters({
      userRelation: userRelationFilter,
      hasViolation,
      senderId,
      executorId,
      flowType,
      scheduledToday,
    });
  }, [
    userRelationFilter,
    hasViolation,
    senderId,
    executorId,
    flowType,
    scheduledToday,
  ]);

  const currentUserId = authStore.getInitialInfo?.identity?.id;

  const tags = useApi(
    () =>
      api.tags.getAll({
        pageSize: -1,
        createByUserId: currentUserId,
      }),
    null,
    () => {},
    {},
    false
  );

  const allTagsList = useMemo(() => {
    return tags.value?.items ?? []
  }, [tags.value?.items]);

  const filterTagsList = useApi(
    () =>
      api.issue.getTags({
        pageSize: -1,
        boardId: authStore.getCurrentBoardId,
        dateDeadlineTo: communicationStore.getMode !== CommunicationModeKey.CalendarPlanning && isDeadlineToday
          ? dayjs().tz().endOf("day").toISOString()
          : undefined,
        scheduledForToday: communicationStore.getMode !== CommunicationModeKey.CalendarPlanning
          ? scheduledToday
          : undefined,
        isActive: true,
        isArchived: false,
        userRelation: communicationStore.getMode !== CommunicationModeKey.CalendarPlanning
          ? userRelationFilter
          : RelationsKeys.My,
        hasViolation: communicationStore.getMode !== CommunicationModeKey.CalendarPlanning
          ? userRelationFilter
          : undefined,
        userId: communicationStore.getMode === CommunicationModeKey.CalendarPlanning
          ? calendarSubordinatesFilter
          : subordinatesFilter,
        ...filterGenerator.getReqFilters(communicationStore.getMode === CommunicationModeKey.CalendarPlanning),
      }),
    null,
    () => {},
    {},
    false
  );

  const subordinates = usePagingWithController(
    api.issue,
   undefined,
    {
      pageSize: -1,
      maxDepth: -1,
    },
    undefined,
    undefined,
    undefined,
    undefined,
    `users-for-filter`,
  );

  useEffect(() => {
    subordinates.restart();
    if (communicationStore.getMode !== CommunicationModeKey.TablePlanning && (
      location.pathname.includes("my-plan") ||
      location.pathname.includes("work-plans") ||
      location.pathname.includes("approve-plans"))
    ) {
      communicationStore.setMode(CommunicationModeKey.TablePlanning);
    }
  }, []);

  const subordinatesList = useMemo(() => {
    return subordinates.items;
  }, [subordinates.items]);

  const tagsList = useMemo(() => {
    return filterTagsList.value?.items ?? [];
  }, [filterTagsList.value?.items]);

  const [isLoadingTags, setIsLoadingTags] = useState<boolean>(false);

  const firstFetchTags = useCallback(async () => {
    // setIsLoadingTags(true);
    // const resp = await tags.fetch();
    //
    // if (resp?.items.length) {
    //   const filterState = sortAndFilter.find(
    //     (v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
    //   )?.filterState;
    //   const { tags = [] } = filterState ?? {};
    //   const filteredTags = tags.filter((item) => resp?.items.find((tag) => tag.id === item));
    //   handleSelectedTags(filteredTags);
    // }

    // setIsLoadingTags(false);
  }, []);

  const tagsChannel = useMemo(() => new BroadcastChannel("tags"), []);

  useEffect(() => {
    tagsChannel?.addEventListener("message", refetchTags);

    return () => {
      tagsChannel?.removeEventListener("message", refetchTags);
    };
  }, [tagsChannel]);

  // const fetchFilterTagsList = async () => {
  //   await filterTagsList.fetch();
  // };

  useEffect( () => {
    if (communicationStore.getMode === CommunicationModeKey.CalendarPlanning
      || communicationStore.getMode === CommunicationModeKey.Kanban
    ) {
      filterTagsList.fetch();
    }
  }, [
    userRelationFilter,
    hasViolation,
    senderId,
    executorId,
    flowType,
    scheduledToday,
    communicationStore.getMode,
  ]);

  useEffect(() => {
    tags.fetch();
  }, []);


  const [subordinatesFilter, setSubordinatesFilter] = useLocalStorage<number[]>(
    "subordinates-filter",
    []
  );
  // const [subordinatesFilter, setSubordinatesFilter] = useState<number[]>([]);
  const handleChangeSubordinatesFilter = useCallback((id: number) => {
    setSubordinatesFilter((prev) => prev.includes(id)
      ? prev.filter((item) => item !== id)
      : [...prev, id]
    );
  }, []);

  const handleResetFilter = useCallback(() => {
    setSubordinatesFilter([]);
  }, []);

  // const [calendarSubordinatesFilter, setCalendarSubordinatesFilter] = useLocalStorage<number[]>(
  //   "calendar-subordinates-filter",
  //   []
  // );


  const isActiveFilter = useMemo(() => {
    const filterState = sortAndFilter.find(
      (v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
    )?.filterState;

    const {
      isDeadlineToday,
      hasViolation,
      scheduledToday,
      senderId,
      executorId,
      flowType = 3,
      tags = [],
    } : {
      senderId?: number;
      executorId?: number;
      hasViolation?: boolean | false;
      scheduledToday?: boolean;
      flowType?: number | undefined;
      isDeadlineToday?: boolean | false;
      tags?: number[]
    } = filterState ?? {};

    const usersFilter = userRelationFilter === RelationsKeys.My
      ? !!senderId
      : !!executorId;

    const activeUserFilter = userRelationFilter === RelationsKeys.Participated
      ? (!!executorId || !!senderId)
      : usersFilter;

    return isDeadlineToday || hasViolation || activeUserFilter ||
      flowType !== 3 || scheduledToday || !!filterTags.length || isGroupingByTags || !!subordinatesFilter.length;
  }, [
    authStore.getCurrentCompanyId,
    sortAndFilter,
    userRelationFilter,
    filterTags,
    isGroupingByTags,
    subordinatesFilter,
  ]);

  const isActiveCalendarFilter = useMemo(() => {
    return !!calendarRoleId || filterTags.length > 0 || !!calendarSubordinatesFilter.length;
  }, [calendarRoleId, filterTags, calendarSubordinatesFilter]);

  const refetchTags = useCallback(async () => {
    tags.fetch();
    setIsLoadingTags(true);
    await filterTagsList.fetch();
    setIsLoadingTags(false);
  }, [calendarSubordinatesFilter, subordinatesFilter, communicationStore.getMode]);


  useEffect(() => {
    refetchTags();

  }, [calendarSubordinatesFilter, subordinatesFilter]);


  return (
    <BoardFilterContext.Provider
      value={{
        userRelation: userRelationFilter,
        boardId: authStore.getCurrentBoardId,
        sortValue:
          sortAndFilter.find((v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId)
            ?.sortValue ?? sortKeys.date,
        hasViolation,
        scheduledToday,
        isDeadlineToday,
        senderId,
        executorId,
        userId: communicationStore.getMode === CommunicationModeKey.CalendarPlanning
          ? calendarSubordinatesFilter
          : subordinatesFilter,
        flowType,
        tags: filterTags,
        board: board ?? undefined,
        boardColumnsState,
        onChangeUserRelation: handleChangeUserRelation,
        isGroupingByOrgcharts,
        isGroupingByUsers: subordinates.items.length ? isGroupingByUsers : false,
        isGroupingByTags,
        calendarRoleId,
        tagsList,
        allTagsList,
        refetchTags,
        zoom,
      }}
    >
      <VideoDialog
        name="communication"
        trainingKey="ed.c.i"
        onWatchedClickObject={{ communication: true }}
        hidden={false}
      />
      {/*<TextEditorBugInfoDialog isOpen={isEditorDialogShow} setIsOpen={handleClose} />*/}
      {/*<Alert type="warning" className="py-1 px-2" message={t("parse:text_editor_bug_alert")} />*/}
      <PageHeader>
        <div
          className="d-stack-row justify-space-between align-center mb-2 spacing-0 full-width communication-header"

        >
          <div className="communication-header-row d-stack-row align-center justify-start">
            {communicationStore.getMode === CommunicationModeKey.Kanban ? (
              <>
                <BoardRelationSwitch
                  boardColumnsState={boardColumnsState}
                  onChangeUserRelation={handleChangeUserRelation}
                />
                {!!subordinatesList.length && (
                  <SubordinatesList
                    maxCount={6}
                    filter={subordinatesFilter}
                    subordinatesList={subordinatesList}
                    onChangeFilter={handleChangeSubordinatesFilter}
                    onResetFilter={handleResetFilter}
                  />
                )}
              </>
            ) : communicationStore.getMode === CommunicationModeKey.CalendarPlanning && (
              <>
              <CommunicationHeaderContentCalendarPlanning />
                <SubordinatesList
                  maxCount={6}
                  filter={calendarSubordinatesFilter}
                  subordinatesList={subordinatesList}
                  isChoiceOnlyOneUser
                  onChangeFilter={handleChangeCalendarSubordinatesFilter}
                  onResetFilter={handleResetCalendarSubordinatesFilter}
                />
              </>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div className="d-stack-row align-center justify-space-between spacing-2">
              {communicationStore.getMode === CommunicationModeKey.Kanban ? (
                <>
                  <SortButton
                    value={
                      sortValues.find(
                        (item) =>
                          item.key ===
                          sortAndFilter.find(
                            (v) => v.key == userRelationFilter && v.companyId == authStore.getCurrentCompanyId
                          )?.sortValue
                      ) ?? (sortValues.find((v) => v.id == 1) as valueType)
                    }
                    sortMenuValues={sortMenuValues}
                  />
                  <FilterButton
                    dropdownContent={
                      <FilterDropdownContent
                        isLoading={isLoading}
                        onChangeFlowTypeFilter={handleChangeFlowTypeFilter}
                        onChangeSenderId={handleChangeSenderIdFilter}
                        onChangeExecutorId={handleChangeExecutorIdFilter}
                        onChangeHasViolation={handleChangeHasViolationFilter}
                        onChangeScheduledToday={handleChangeScheduledTodayFilter}
                        isShowUserGrouping={subordinatesList.length > 0}
                        isGroupingByOrgcharts={isGroupingByOrgcharts}
                        onChangeIsGroupingByOrgcharts={handleChangeIsGroupingByOrgcharts}
                        isGroupingByTags={isGroupingByTags}
                        onChangeIsGroupingByTags={handleChangeIsGroupingByTags}
                        isGroupingByUsers={isGroupingByUsers}
                        onChangeIsGroupingByUsers={handleChangeIsGroupingByUsers}
                        onChangeIsDeadlineToday={handleChangeForTodayTasksFilter}
                        onSelectedTags={handleSelectedTags}
                        onChangeIsOpenFilter={handleChangeIsOpenFilter}
                      />
                    }
                    isActiveFilter={isActiveFilter}
                    isOpenFilter={isOpenFilter}
                    onChangeIsOpenFilter={handleChangeIsOpenFilter}

                  />
                  <ZoomButton value={zoom} onChange={setZoom} />
                  <ArchiveButton />
                </>
              ) : communicationStore.getMode === CommunicationModeKey.CalendarPlanning && (
                <>
                  <DownloadButton
                    handleClickDownload={handleClickDownloadReport}
                  />
                  <FilterButton
                    placement={"bottomRight"}
                    dropdownContent={
                      <CalendarFilterDropdownContent
                        isLoading={isLoading}
                        handleChangeCalendarRoleId={handleChangeCalendarRoleId}
                        rolesList={calendarSubordinatesFilter.length ? usersRolesList : rolesList}
                        onSelectedTags={handleSelectedTags}
                        onChangeIsOpenFilter={handleChangeIsOpenFilter}
                      />
                    }
                    isActiveFilter={isActiveCalendarFilter}
                    isOpenFilter={isOpenFilter}
                    onChangeIsOpenFilter={handleChangeIsOpenFilter}
                  />
                </>
              )}
              {isShowCommunicationModeSwitch ? (
                <CommunicationModeSwitch />
              ) : null}
              <CreateIssueButton />
            </div>
          </div>
        </div>
      </PageHeader>
      {communicationStore.getMode === CommunicationModeKey.Kanban ? (
        <PageContent
          className={"communication-kanban"}
          isLoading={isLoading || isLoadingTags}
          zoom={{ percentage: zoom }}
        >
          {!isLoadingTags && (
            <>
              {(isGroupingByOrgcharts || isGroupingByTags || isGroupingByUsers) ? (
                <BoardCollapse
                  onChangeBoardColumnsState={handleChangeBoardColumnsState}
                />
              ) : (
                <DragAndDropContainer
                  onChangeBoardColumnsState={handleChangeBoardColumnsState}
                />
              )}
            </>
          )}
        </PageContent>
      ) : communicationStore.getMode === CommunicationModeKey.CalendarPlanning ? (
        <CommunicationPageContentCalendarPlanning />
      ) : (
        <PlanningPage />
      )}
    </BoardFilterContext.Provider>
  );
}

export default observer(CommunicationPageViewObserver);
