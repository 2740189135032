import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PlanningCollapseContentTable.scss";
import { PlanningCollapseContentTableView } from "./PlanningCollapseContentTableView";
import { DatePicker, IconButton, TimeSpanPicker, Tooltip } from "../../../../../../uiKit";
import { AiOutlineCalendar } from "@react-icons/all-files/ai/AiOutlineCalendar";
import { ColumnsType } from "antd/es/table";
import { css } from "@emotion/css";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { IPlanningCollapseContentTable } from "./PlanningCollapseContentTable.interface";
import { useTranslation } from "react-i18next";
import { IssueSwitchStatus } from "../../../../../../elements/issueSwitchStatus/IssueSwitchStatus";
import { getObjectFromTimeSpan, timeSpanOperation } from "../../../../../../../helpers/dateFunctions";
import { IssueQueryKeys } from "../../../../../../../constants/issueTypeKeys";
import { useNavigate } from "react-router-dom";
import { ICustomIssue } from "../PlanningCollapseContent.interface";
import dayjs from "dayjs";
import { IoExitOutline } from "@react-icons/all-files/io5/IoExitOutline";
import { removeLeadingZerosFromString } from "../../../../../../../helpers/stringFunctions";
import { useRootStore } from "../../../../../../../hooks";
import { IssueDto } from "../../../../../../../api";
import { CalendarGoogleEventBlock } from "../../../../../dialogs/calendarGoogleEventBlock/CalendarGoogleEventBlock";
import { ACCEPTED, DECLINED, TENTATIVE } from "../../../../../../../constants/googleCalendarEvent";
import { GoogleCalendarEventDto } from "../../../../../../../api/models/GoogleCalendarEventDto";
import { CalendarTimeBlock } from "../../../../../dialogs/calendarTimeBlock/CalendarTimeBlock";
import { CalendarEventTimeBlockDto } from "../../../../../../../api/models/CalendarEventTimeBlockDto";

export const PlanningCollapseContentTable = (props: IPlanningCollapseContentTable) => {
  useEffect(() => {
    console.log("PlanningCollapseContentTable");
  });
  const { issueInitDataStore, appStore } = useRootStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLocale = appStore.getAppLocale;

  const [googleCalendarEvent, setGoogleCalendarEvent] = useState<GoogleCalendarEventDto | null>(null);

  const handleCloseGoogleCalendarEvent = () => {
    setGoogleCalendarEvent(null);
  }

  const getResponseStatus = (responseStatus: string) => {
    if (responseStatus === DECLINED || responseStatus === TENTATIVE || responseStatus === ACCEPTED) {
      return responseStatus;
    }
    return null;
  };

  const handleClickGoogleEvent = (event: any) => {
    const {
      googleCalendarData, estimatedEndDate: dateWorkEnd, dateWorkStart, ...googleEventData
    } = event;
    const { url, responseStatus } = googleCalendarData ?? {};
    const startDate = dayjs(dateWorkStart).format("YYYY-MM-DD");
    const endDate = dayjs(dateWorkEnd).format("YYYY-MM-DD");
    const isSameDay = dayjs(startDate).isSame(dayjs(endDate), "day");

    const eventTime = isSameDay
      ? `${dayjs(dateWorkStart).format("L LT")} - ${dayjs(dateWorkEnd).format("LT")}`
      : `${dayjs(dateWorkStart).format("LLL")} - ${dayjs(dateWorkEnd).format("LLL")}`;

    setGoogleCalendarEvent({
      ...googleEventData,
      ...googleCalendarData,
      dateWorkStart: dayjs(dateWorkStart),
      dateWorkEnd: dayjs(dateWorkEnd),
      status: getResponseStatus(responseStatus),
      url: url ? `<a href="${url}" target="_blank">${url}</a>` : "",
      eventTime,
    });
  };

  const [timeBlockEvent, setTimeBlockEvent] = useState<CalendarEventTimeBlockDto | null>(null);

  const handleClickTimeBlock = (event: any) => {
    const { dateWorkStart, timePlan } = event;
    const { hours = 0,  minutes = 0, seconds = 0 } = getObjectFromTimeSpan(timePlan!);
    const dateWorkEnd = dayjs(dateWorkStart)
      .add({
        hours,
        minutes,
        seconds,
      })
    setTimeBlockEvent({
      ...event,
      dateWorkEnd,
      dateWorkStart: dayjs(dateWorkStart),
      isReadOnly: true,
    });
  };

  const onCloseTimeBlock = () => {
    setTimeBlockEvent(null);
  }

  const handleOpenIssue = useCallback((issue: any) => {
    if (props.isGoogle) {
      handleClickGoogleEvent(issue);
    } else {
      if (issue.recurringIssueId && issue.recurringIssueId === issue.id) {
        handleClickTimeBlock(issue);
      } else {
        navigate({
          search: `?${IssueQueryKeys.issueId}=${issue.id ?? issue.recurringIssueId}`,
        });
        issueInitDataStore.setOnStatusChange = props.onLoadRestart;
        issueInitDataStore.setOnChangeDefiniteField = props.onLoadRestart;
      }
    }
  }, []);

  const timePlansArr = props.issues
    ?.filter((i) => !i.isDeleted && !i.isExcludedFromPlan && (i.issue.timePlan ?? "").trim().length > 0)
    .map((i) => i.issue.timePlan);

  const timeFactsArr = props.issues
    ?.filter((i) => !i.isDeleted && !i.isExcludedFromPlan && (i.issue.timeFact ?? "").trim().length > 0)
    .map((i) => i.issue.timeFact);


  const timePlanResult = timeSpanOperation().add(timePlansArr as string[]);
  const timeFactResult = timeSpanOperation().add(timeFactsArr as string[]);

  const getTimeWithHoursAndMinutes = (time: string) => {
    const hours = removeLeadingZerosFromString(getObjectFromTimeSpan(time).totalHours ?? 0);
    const minutes = removeLeadingZerosFromString(getObjectFromTimeSpan(time).minutes ?? 0);
    return { hours, minutes };
  };

  const columns: ColumnsType<ICustomIssue> = useMemo(() => {
    const columnsList: ColumnsType<ICustomIssue> = [
      {
        title: props.title,
        dataIndex: "issues",
        className: "issue-info",
        render: (_, record) => {
          return (
            <div className="issue-info-content">
              <div
                style={{
                  cursor: !record.isSchedule && !record.isDeleted ? "pointer" : "default",
                  overflow: "hidden",
                }}
                onClick={() => !record.isSchedule && !record.isDeleted && handleOpenIssue(record.issue)}
              >
                <Tooltip title={record.isDeleted ? t("notifier:success.issue_deleted") : ""}>
                  {record.issue.name}
                </Tooltip>
              </div>
              <div className="issue-info-content__result">
                {record.issue?.awaitedResult}
              </div>
            </div>

          );
        },
      },
      {
        title: `${t("ui:planning_table.plan")} \n (${getTimeWithHoursAndMinutes(timePlanResult).hours}${t(
          "common:planning.hour"
        ).trim()} ${getTimeWithHoursAndMinutes(timePlanResult).minutes}${t("common:planning.minute").trim()})`,
        dataIndex: "plan",
        className: "issue-plan-fact",
        // width: 150,
        render: (_, record) => {
          const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "TimePlan");
          return (
            <TimeSpanPicker
              disabled={!!(record.isDeleted || record.isExcludedFromPlan || props.planStatus == 2 || record.isSchedule || isBlocked)}
              size="middle"
              allowClear={false}
              value={record.issue.timePlan}
              readOnly={props.isGoogle}
              onChange={(date, ...arg) => {
                props.onIssueEdit(record.issue.id ?? null, {
                  ...record.issue,
                  timePlan: date,
                }, {
                  path: "timePlan",
                  value: date,
                });
              }}
            />
          );
        },
        align: "center",
      },
      {
        title: `${t("ui:planning_table.fact")} \n (${getTimeWithHoursAndMinutes(timeFactResult).hours}${t(
          "common:planning.hour"
        ).trim()} ${getTimeWithHoursAndMinutes(timeFactResult).minutes}${t("common:planning.minute").trim()})`,
        dataIndex: "fact",
        align: "center",
        className: "issue-plan-fact",
        // width: 150,
        render: (_, record) => {
          const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "TimeFact");
          return props.planStatus == 3 ? (
            <TimeSpanPicker
              disabled={!!(record.isDeleted || record.isExcludedFromPlan || record.isSchedule || isBlocked)}
              size="middle"
              allowClear={false}
              value={record.issue.timeFact}
              readOnly={props.isGoogle}
              onChange={(date) => {
                props.onIssueEdit(record.issue.id ?? null, {
                  ...record.issue,
                  timeFact: date,
                }, {
                  path: "timeFact",
                  value: date,
                });
              }}
            />
          ) : (
            `${getTimeWithHoursAndMinutes(record.issue.timeFact ?? "00:00:00").hours}${t(
              "common:planning.hour"
            ).trim()} ${getTimeWithHoursAndMinutes(record.issue.timeFact ?? "00:00:00").minutes}${t(
              "common:planning.minute"
            ).trim()}`
          );
        },
      },
      {
        title: props.isGoogle ? t("ui:planning_table.date_time") : t("ui:planning_table.start"),
        dataIndex: "start",
        className: "issue-start-end",
        render: (_, record) => {
          const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "DateWorkStart");
          return (
            <DatePicker
              value={record.issue.dateWorkStart ? dayjs(record.issue.dateWorkStart as string) : null}
              onChange={(date) =>
                props.onIssueEdit(record.issue.id ?? null, {
                  ...record.issue,
                  dateWorkStart: date?.toISOString()
                }, {
                  path: "dateWorkStart",
                  value: date?.toISOString(),
                })
              }
              readOnly={props.isGoogle}
              showTime={{ format: "HH:mm" }}
              disablePast
              placeholder={t("ui:planning_table.start")}
              size="middle"
              disabled={!!(record.isDeleted || record.isExcludedFromPlan || props.planStatus == 2 || record.isSchedule || isBlocked)}
            />
          );
        },
        align: "center",
      },
    ];
    if (!props.isGoogle) {
      columnsList.push(  {
        title: t("ui:planning_table.deadline"),
        dataIndex: "end",
        className: "issue-start-end",
        render: (_, record) => {
          const isBlocked = record.issue?.calculated?.blockedFields?.find((i: string) => i == "DateDeadline");
          return (
            <DatePicker
              value={record.dateDeadline ? dayjs(record.dateDeadline as string) : null}
              onChange={(date) =>
                props.onIssueEdit(record.issue.id!, {
                  ...record.issue,
                  dateDeadline: date?.toISOString()
                }, {
                  path: "dateDeadline",
                  value: date?.toISOString(),
                })
              }
              showTime={{ format: "HH:mm" }}
              disablePast
              placeholder={t("ui:planning_table.deadline")}
              size="middle"
              disabled={!!(record.isDeleted || record.isExcludedFromPlan || props.planStatus == 2 || record.isSchedule || isBlocked)}
            />
          );
        },
        align: "center",
      },
      )
    }
    return [
      ...columnsList,
      {
        title: t("ui:planning_table.status"),
        dataIndex: "status",
        className: "status",
        render: (_, record) => {
          const date = record.dateRelease && new Date(record.dateRelease as string);
          return record.isSchedule ? (
            <div className="d-stack-row align-center justify-space-around">
              <IoExitOutline size={19} />
              {dayjs(date).format(currentLocale == "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
            </div>
          ) :
            !record.isGoogle ? (
              <IssueSwitchStatus
                isDisabled={record.isDeleted || record.isExcludedFromPlan || props.planStatus == 2 || record.isSchedule}
                issueData={record.issue as IssueDto}
                actions={record.issue.calculated?.actions ?? []}
                onRefreshData={props.onLoadRestart}
              />
            ) : (
              <div
                className={`google-event-status google-event-status_${record.issue.googleCalendarData?.internalGoogleEventStatus?.toLowerCase()}`}
              >
                { t(`ui:planning_table.google_events_status.${record.issue.googleCalendarData?.internalGoogleEventStatus ?? ""}`)}
              </div>

          );
        },
        align: "center",
      },
      {
        title: "",
        dataIndex: "delete",
        className: `issue-plan-icon-button ${props.isGoogle ? "issue-plan-icon-button_google" : ""}`,
        render: (_, record) => {
          return !props.isGoogle && props.issues && props.issues.length >= 1 ? (
            <IconButton
              disabled={record.isDeleted || record.isExcludedFromPlan || props.planStatus == 2 || record.isSchedule}
              loading={props.loadingTaskId == record.issue.id}
              style={{ margin: "0 auto" }}
              onClick={() => props.onIssueDelete(record.issue ?? null)}
              icon={<FiX />}
            />
          ) : null
        },
        align: "center",
      },
    ]
  }, []);

  return (
    <>
      {!!googleCalendarEvent && (
        <CalendarGoogleEventBlock
          event={googleCalendarEvent}
          onClose={handleCloseGoogleCalendarEvent}
        />
      )}
      {!!timeBlockEvent && (
        <CalendarTimeBlock
          item={timeBlockEvent}
          popupItems={[]}
          onClose={onCloseTimeBlock}
        />
      )}
      <PlanningCollapseContentTableView columns={columns} dataSource={props.issues ?? []} />
    </>
  )
};