import React, { createRef, KeyboardEventHandler, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tag } from "../../dataDisplay/tags/tag/Tag";
import { DropdownMenu } from "../../../service/dropdownMenu/dropdownMenu";
import { ColorPicker } from "../../colorPicker/ColorPicker";
import "./SelectTagsOption.scss";
import { getColor } from "../../../../utils/hex2rgb";
import { CloseIcon } from "../../../../constants/icon";
import clsx from "clsx";
import { createPortal } from "react-dom";

export const SelectTagsOption = memo((props: any) => {
  // const dropdownMenuItems = useMemo(() => {
  //   return [
  //     {
  //       id: 1,
  //       text: 'Переименовать',
  //       action: () => {
  //         console.log('Переименовать');
  //         props.onChangeRenameTag(props.tag.value)
  //
  //       },
  //     },
  //     {
  //       id: 2,
  //       text: 'Изменить цвет',
  //       action: () => {
  //         // if (currentTag) {
  //         //   setEditTag(currentTag);
  //         //   setIsShowColorPicker(true);
  //         // }
  //         props.onChangeShowColorPicker(props.tag.value)
  //         console.log('Изменить цвет');
  //       },
  //     }
  //   ]
  // }, []);

  const handleRemoveTag = () => {
    console.log(111, props.tag);
    props.onRemoveTag(props.tag);
  };

  const handleChangeColor = (color: string) => {
    console.log("handleChangeColor", color);
    console.log("props.tag", props.tag);
    props.onChangeColor({...props.tag, color});
  };

  const [colorPickerPosition, setColorPickerPosition] = useState({
    top: 0,
    left: 0,
  });
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleOpenChange = (value: boolean) => {
    console.log("tag", tagAction.current);
    console.log();
    if (value) {
      const { top = 0, left = 0 } = tagAction.current?.getBoundingClientRect() ?? {};
      console.log("top", top);
      console.log("left", left);
      setColorPickerPosition({
        top: top + 24,
        left,
      });
    }
    setIsOpenDropdown(value);
  };

  const [editTag, setEditTag] = useState<any>(null);

  const handleRenameTag = (e: any) => {
    setEditTag(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    console.log("handleKeyDown", e);

    const { key } = e;
    switch (key) {
      case "Enter":
        props.updateTag({
          ...props.tag,
          value: editTag,
        });
        setEditTag(null);
        props.onChangeRenameTag("");
        break;
      case "Escape":
        setEditTag(null);
        props.onChangeRenameTag("");
        break;
    }
  }

  useEffect(() => {
    if (props.renameTag === props.tag.value) {
      setEditTag(props.tag.value);
    }
  }, [props.renameTag]);

  const isRenameTag = useMemo(() => {
    return props.renameTag === props.tag.value;
  }, [props.renameTag, props.tag.value]);

  const tagAction = createRef<HTMLDivElement>();

  return (
    <>
      {props.showColorPicker === props.tag.value &&
        createPortal(
          <div
            style={{
            position: "fixed",
            top: `${colorPickerPosition.top}px`,
            left: `${colorPickerPosition.left}px`,
            zIndex: 10000,
          }}
          >
          <ColorPicker
            activeColor={props.tag?.color}
            onChangeColor={handleChangeColor}
          />
        </div>
       ,document.body)}
    <div
      className={clsx("selected-tag", {
        "selected-tag_active": isOpenDropdown || props.showColorPicker === props.tag.value
      })}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Tag
        closable
        className="d-flex align-center justify-center"
        style={{
          height: "24px",
          borderColor: getColor(props.tag?.color, 0.4),
          background: getColor(props.tag?.color, 0.05),
          color: getColor(props.tag?.color),
        }}
        color={props.tag?.color}
        bordered={false}
        onClose={handleRemoveTag}
      >
        {/*{isRenameTag && (*/}
        {/*  <input*/}
        {/*    className={"selected-tag__input"}*/}
        {/*    type="text"*/}
        {/*    value={editTag}*/}
        {/*    autoFocus*/}
        {/*    onInput={handleRenameTag}*/}
        {/*    onKeyDown={handleKeyDown}*/}
        {/*  />*/}
        {/*)}*/}
        <span
          // className={clsx("selected-tag__value", {"selected-tag__value_transparent": isRenameTag})}
          className={"selected-tag__value"}
        >
          { props.tag.value }
          {/*{ isRenameTag ? editTag : props.tag.value }*/}
        </span>


        {/*<input*/}
        {/*  type="text" */}
        {/*  value={option.label}*/}
        {/*  onClick={(e) => e.stopPropagation()}*/}
        {/*  onMouseDown={(e) => e.stopPropagation()}*/}
        {/*/>*/}
        {/*<div*/}
        {/*  ref={tagAction}*/}
        {/*  className="selected-tag-action"*/}
        {/*  onClick={(e) => {*/}
        {/*    console.log("e", e);*/}
        {/*    e.stopPropagation();*/}
        {/*  }}*/}
        {/*  onMouseDown={(e) => {*/}
        {/*    e.stopPropagation();*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    background: getColor(props.tag?.color),*/}
        {/*    color: props.tag?.color && props.tag?.color !== "#252525" ? "#171E40" : "#fff",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <DropdownMenu*/}
        {/*    items={dropdownMenuItems}*/}
        {/*    iconType={"dots-horizontal"}*/}
        {/*    onOpenChange={handleOpenChange}*/}
        {/*  />*/}
        {/*  <div*/}
        {/*    className="selected-tag-action__close"*/}
        {/*    onClick={handleRemoveTag}*/}
        {/*  >*/}
        {/*    <CloseIcon size={12}/>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Tag>
    </div>
      </>
  )
});