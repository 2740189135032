import React, { forwardRef, memo, useState, useCallback, useMemo } from "react";

import { AiOutlinePaperClip } from "@react-icons/all-files/ai/AiOutlinePaperClip";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../../../../service/fileUploader/FileUploader";
import { Button, Icon, Tooltip } from "../../../../../../../uiKit";
import { blockedFieldsKeys, IssueHistoryConst, perrmissionsToSendCommentKeys } from "../../../../../types/consts";
import { IIssueHistorySenderBlockView } from "./IssueHistorySenderBlock.interface";
import { TextEditor } from "../../../../../../textEditor/TextEditor";
import { initialEditorContent } from "../../../../../../../../utils/textEditor";
import { ToolbarType } from "../../../../../../textEditor/types/types";
import debounce from "lodash/debounce";

export const IssueHistorySenderBlockView = memo(
  forwardRef((props: IIssueHistorySenderBlockView, ref) => {
    const { t } = useTranslation();

    const [comment, setComment] = useState(props.commentIssue);

    const handleChangeComment = useCallback((value: string) => {
      setComment(value);
    }, []);

    const handleBlur = useCallback(() => {
      props.onChangeComment(comment);
    }, [comment]);

    const handleClickSend = useCallback((text?: string) => {
      if (!props.getIsDisabled()) {
        props.onSendComment(IssueHistoryConst.Comment, text ?? comment);
      }
    }, [IssueHistoryConst.Comment, comment, props]);

    return (
      <div className="mb-4">
        <div className="full-width">
          {/*<TextEditorOld*/}
          {/*  id="issue-history-editor"*/}
          {/*  placeholder={t("ui:placeholder.write_comment")}*/}
          {/*  value={props.commentIssue}*/}
          {/*  initialValue={props.commentIssue}*/}
          {/*  ref={props.editorRef}*/}
          {/*  readOnly={!(props.allowedCommentsTypes && props.allowedCommentsTypes?.length > 0)}*/}
          {/*  onChange={props.onChangeComment}*/}
          {/*  toolbarType={ToolbarType.Dynamic}*/}
          {/*/>*/}


          <TextEditor
            id={"issue-history-sender-block-" + props.issueId}
            ref={ref}
            issueId={props.issueId}
            placeholder={t("ui:placeholder.write_comment")}
            value={comment}
            initialValue={initialEditorContent}
            disabled={!(props.allowedCommentsTypes && props.allowedCommentsTypes?.length > 0)}
            // onChange={props.onChangeComment}
            onChange={handleChangeComment}
            toolbarType={ToolbarType.Dynamic}
            onBlur={handleBlur}
            isSimplifiedToolbar
            onSave={handleClickSend}
          />
        </div>
        <div className="full-width mt-1">
          <FileUploader
            maxSizeMb={40}
            smallSize
            relatedInputId="issue-comment-area-ctrlv-communications"
            value={props.attachments}
            // value={props.attachments.filter((a) => a.type != 3)}
            onUpload={(data) => props.setAttachments(data)}
            uploadFilesInputRef={props.uploadFilesInputRef}
            filesContainerMaxHeight="35vh"
            onAnyItemDelete={props.onDeleteAttachmentById}
            onUploadStatusChange={props.setIsFileLoaderUploadingSync}
            hideInfo
          />
        </div>
        <div
          className="d-stack-row justify-space-between align-start spacing-2 mt-2"
          // style={{ marginTop: props.attachments.length > 0 ? 1 : 0 }}
        >
          <div className="d-stack-row align-center spacing-2">
            <Tooltip trigger={["hover"]} title={t("ui:button.attach")}>
              <Button
                disabled={!(props.allowedCommentsTypes && props.allowedCommentsTypes?.length > 0)}
                onClick={() => (props.uploadFilesInputRef as any)?.current?.click()}
                icon={<Icon component={() => <AiOutlinePaperClip />} />}
                variant="default"
                // style={{ fontSize: "12px", fontWeight: "14px" }}
                // variant="text"
              />
            </Tooltip>
            {/*{(window.location.hostname == "localhost" || window.location.hostname == "beta.tonnus.io") && (*/}
            {/*  <Tooltip title={"Аудиосообщение"}>*/}
            {/*    <Button*/}
            {/*      onClick={() => props.audioRecorderRef.current?.recordingStart()}*/}
            {/*      // icon={<Icon component={() => <FiMic />} />}*/}
            {/*      icon={*/}
            {/*        <Icon*/}
            {/*          component={() =>*/}
            {/*            props.audioRecorderState == "paused" || props.audioRecorderState == null ? <FiMic /> : <FiPause />*/}
            {/*          }*/}
            {/*        />*/}
            {/*      }*/}
            {/*      variant="default"*/}
            {/*    />*/}
            {/*  </Tooltip>*/}
            {/*)}*/}
          </div>
          {/*<div className="flex-grow-1">*/}
          {/*<AudioRecorder*/}
          {/*  ref={props.audioRecorderRef}*/}
          {/*  // isVisibleWhenNotRecording={false}*/}
          {/*  // onStateChange={props.onAudioRecorderStateChange}*/}
          {/*  onStatusChange={props.onAudioRecorderStatusChange}*/}
          {/*  onAudioReady={props.onSaveAudioMessage}*/}
          {/*/>*/}
          {/*</div>*/}
          <div className="d-stack-column justify-center align-end spacing-1">
            {!!props.allowedCommentsTypes?.find((item) => item === perrmissionsToSendCommentKeys.comment) && (
              <Button
                onClick={() => handleClickSend()}
                loading={props.isLoadingSend}
                disabled={props.getIsDisabled()}
                variant="filled"
              >
                {t("ui:button.send")}
              </Button>
            )}
            {/*{props.isAllowAceptButton && (*/}
            {/*  <Button loading={props.loadingAcceptButton} onClick={props.onAcceptComments} variant="filled">*/}
            {/*    {t("ui:button.accept")}*/}
            {/*  </Button>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    );
  })
);
