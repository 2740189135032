import React from "react";
import { IEducationSectionView } from "./EducationSection.interface";
import { EducationCard } from "../edicationCard/EducationCard";
import "./EducationSection.scss";
import { Title } from "../../../../../uiKit";

export const EducationSectionView = (props: IEducationSectionView) => {
  return (
    <div className="d-stack-column spacing-3 full-width">
      <Title level={4} weight={500} children={props.educationItem.name} />
      <div className="education-section-container">
        {props.educationItem.children?.map((item) => (
            <EducationCard
              key={item.key}
              educationItem={item}
              trainingKey={item.key}
              onOpenTrainingDialog={props.onClick}
              indicator={props.onGetProgress(item?.videoId)}
              onSuccessfulCompletion={props.onSuccessFullCompletion}
            />
        ))}
      </div>
    </div>
  );
};
