import React from "react";
import "./InputNumber.scss";
import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";
import clsx from "clsx";

interface IInputNumber extends InputNumberProps {
  readOnly?: boolean;
  // numbersOnly?: boolean;
}

export function InputNumber({ className, readOnly, ...rest }: IInputNumber) {
  const parseInputData = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (rest.max == null) return;
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key) && !(e.code == "Minus")) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (String(e.currentTarget.value).length >= "999999999999999".length) {
      // if (Number(e.currentTarget.value) >= 999999999999999) {
      if (key == "ArrowLeft" || key == "ArrowRight" || key == "Backspace" || key == "Delete") return;
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <AntdInputNumber
      size="large"
      type="number"
      className={clsx("uiKit-inputNumber", className ?? "", {
        "no-interact": readOnly,
      })}
      onKeyPress={parseInputData}
      {...rest}
    />
  );
}
