import React, { useEffect, useState, useCallback, useRef } from "react";
import "./CommentCard.scss";
import { UserCard } from "../userCard/UserCard";
import FileUploader from "../../service/fileUploader/FileUploader";
import { ICommentCardView } from "./CommentCard.interface";
import { Reactions } from "../emojiPicker/Reactions";
import { TextEditor as TextEditorLexical } from "../../modules/textEditor/TextEditor";
import { initialEditorContent } from "../../../utils/textEditor";
import { Button } from "../../uiKit";
import { useTranslation } from "react-i18next";
import { DotIcon } from "../../../constants/icon";
import { DeleteCommentDialog } from "../../modules/dialogs/deleteCommentDialog/DeleteCommentDialog";
import { PlanCommentAttachmentDto, StaticFileDto } from "../../../api";


export const CommentCardView = (props: ICommentCardView) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string | undefined>(undefined);
  const editorRef = useRef<{ resetEditor: () => void; isEmptyEditor: () => boolean }>();

  const onChangeComment = useCallback((value) => {
    setComment(value);
  }, []);

  const handleEditClick = () => {
    if (props.handleEditClick) {
      props.handleEditClick(props.historyId);
      setComment(props.commentContent);
    }
  };

  const handleClickCancel = () => {
    if (props.handleEditClick) {
      props.handleEditClick(-1);
    }
    setComment('');
    editorRef.current?.resetEditor();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickSave = async () => {
    console.log("handleClickSave", comment);
    // props.handleSaveClick(props.historyId, comment);
    if (props.item && props.handleSaveClick) {
      const newData = {
        ...props.item,
        comment: {
          ...props.item.comment,
          content: comment,
        }
      };
      setIsLoading(true);
      const editStatus = await props.handleSaveClick(props.historyId, newData);
      setIsLoading(false);
      console.log("editStatus", editStatus);
      if (editStatus) {
        setComment(props.commentContent);
      }
    }
  };

  const [isOpenDeleteCommentDialog, setIsOpenDeleteCommentDialog] = useState<boolean>(false);
  const handleClickRemove = () => {
    setIsOpenDeleteCommentDialog(true);
  }

  const handleCloseDeleteCommentDialog = () => {
    setIsOpenDeleteCommentDialog(false);
  }

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const handleDeleteComment = async () => {
    if (props.handleRemoveComment) {
      setIsDeleteLoading(true);
      const removeStatus = await props.handleRemoveComment(props.historyId);
      if (removeStatus) {
        handleCloseDeleteCommentDialog();
      }
      setIsDeleteLoading(false);
    }
  };

  return (
    <>
      {isOpenDeleteCommentDialog && (
        <DeleteCommentDialog
          onClose={handleCloseDeleteCommentDialog}
          open={isOpenDeleteCommentDialog}
          onDelete={handleDeleteComment}
          loading={isDeleteLoading}
          title={t("ui:title.confirm_delete_comment")}
          description={t("ui:text.confirm_delete_comment")}
        />
      )}
      <div className="comment-card" key={props.historyId}>
        <div className="comment-card__header">
          {props.creator && (
            <UserCard
              userInfo={props.creator}
              boldName
              avatarSize={32}
              additionalInfo={`${props.dateSented}`}
              isNewStyle={props.isNewStyle}
              nameSize={12}
            />
          )}
          {!props.isNewStyle && (
            <Reactions
              historyId={props.historyId}
              reactions={props.reactions}
              strategy={props.strategy}
            />
          )}
        </div>
        <div className={`comment-card__content ${props.isNewStyle && "comment-card__content_new-style"}`}>
          {/*<TextEditorOld*/}
          {/*  id={String(props.historyId - props.index)}*/}
          {/*  readOnly*/}
          {/*  value={stringToContent(props.commentContent!)}*/}
          {/*/>*/}
          <TextEditorLexical
            ref={editorRef}
            readOnly={props.editCommentId !== props.historyId}
            value={props.editCommentId === props.historyId ? comment : props.commentContent}
            variant={props.isNewStyle ? 'new-style' : undefined}
            initialValue={props.commentContent ?? initialEditorContent}
            isNewStyle={props.isNewStyle}
            onChange={onChangeComment}

          />
          { props.editCommentId === props.historyId && (
            <div className="comment-card-content-footer">
              <Button
                onClick={handleClickCancel}
                className="button-icon_17"
                variant="default"
              >
                {t("ui:button.cancel")}
              </Button>
              <Button
                onClick={handleClickSave}
                className="button-icon_17"
                variant="filled"
                loading={isLoading}
                disabled={editorRef.current?.isEmptyEditor()}
              >
                {t("ui:button.save")}
              </Button>
            </div>
          )}

          {/*<TextEditor*/}
          {/*    readOnly*/}
          {/*    value={stringToContent(props.commentContent!)}*/}
          {/*/>*/}
        </div>
        {props.isNewStyle && (
          <div className="comment-card-footer">

            {props.isEditing && (
              <div className="comment-card-footer-action">
                <Button
                  className="px-0"
                  onClick={handleEditClick}
                  variant={"link"}
                  children={t("ui:button.edit")}
                  disabled={props.editCommentId !== -1 && props.editCommentId !== props.historyId}
                />
                <DotIcon/>
                <Button
                  className="px-0"
                  onClick={handleClickRemove}
                  variant={"link"}
                  children={t("ui:button.delete")}
                  disabled={props.editCommentId !== -1 && props.editCommentId !== props.historyId}
                />
              </div>
            )}
            <div className="comment-card__reaction">
              <Reactions
                isNewStyle={true}
                historyId={props.historyId}
                reactions={props.reactions}
                strategy={props.strategy}
                isBorder={true}
                isShowFullEmoji
              />
            </div>
          </div>
        )}
        {props.commentAttachments && (
          <div className="comment-card__attachments">
            <FileUploader
              noDragAndDrop
              value={props?.commentAttachments.map((item: PlanCommentAttachmentDto) => item.file!)}
              hideInfo
              smallSize
              noDelete
            />
          </div>
        )}
      </div>
    </>
  );
};
