import React from "react";
import { UserReactionCard } from "./UserReactionCard";
import { IUserReactionsList } from "../interfaces/Reactions.interface";

export const UserReactionsList: React.FC<IUserReactionsList> = (props) => {
  return (
    <div className="d-stack-row flex-wrap justify-end align-center ma-n1">
      {props.reactions.map((reaction, index) => (
        <UserReactionCard
          disabled={props.disabled}
          key={index}
          reactionChip={reaction}
          onClickByReaction={props.onClickReaction}
          isNewStyle={props.isNewStyle}
        />
      ))}
    </div>
  );
};
