import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SubheaderText from "../../../elements/subheaderText/SubheaderText";
import { useTranslation } from "react-i18next";
import { useClaims, useRootStore } from "../../../../hooks";
import { observer } from "mobx-react-lite";
import { UserDto } from "../../../../api";
import AvatarUserSettings from "../../pages/settings/avatarUserSettings/AvatarUserSettings";
import { Button, DatePicker, Input, InputPhoneNumber, Select, SelectOption, Spin, Tooltip } from "../../../uiKit";
import { theme } from "antd";
import { removeSpecialCharactersFromString } from "../../../../helpers/stringFunctions";
import { TelegramBotFeature } from "../../pages/settings/telegramBotFeature/TelegramBotFeature";
import { ChangePasswordDialog } from "../../dialogs/changePassword/ChangePasswordDialog";
import { CustomConfirmDialog } from "../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { GoogleAuthConnect } from "../../pages/settings/googleCalendarAuth/GoogleAuthConnect";
import "./EmployeeEditForm.scss";
import dayjs from "dayjs";
import { PersonalInfoDto } from "../../../../api/models/UserDto";
import { isEqual } from "lodash";
import { permissionKeys } from "../../../../utils/permissions";

export interface IEmployeeEditFormView {
  userId: number;
  currentUserId: number;
  employeeData: UserDto | null;
  checkEmailCode: string;
  firstLaunch: boolean;
  updater: any;
  canShowError: any;
  codeSendTimer: number;
  isUserTypeEditAvailable: boolean;
  isAccessTypeEditAvailable: boolean;
  onSaveClick?: () => void;

  handleAvatarChange(id: number): void;

  handleAvatarDelete(): void;

  handleConfirmEmail(): void;

  sendConfirmationCodeAgain(): void;

  setCheckEmailCode(value: string): void;

  setCanShowError(value: any): void;
}

function EmployeeEditFormView(props: IEmployeeEditFormView) {
  const { t } = useTranslation();
  const { helperStore, authStore } = useRootStore();
  const { useToken } = theme;
  const { token } = useToken();

  const claims = useClaims();

  const isHasEditPermission = useMemo(() => {
    return claims.has(permissionKeys.user.edit);
  }, []);

  const timeZones = helperStore.getTimezones;
  const accessTypes = [
    { id: 0, value: 0, text: t("parse:full_access") },
    { id: 1, value: 1, text: t("parse:limited_access") },
  ];
  const parseRegex = require("regex-parser");

  function matchExact(r: RegExp, str: string) {
    const match = str?.match(r);
    return match && str === match[0];
  }

  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] = useState(false);
  const handleClickChangePassword = () => {
    setIsOpenChangePasswordDialog(true);
  }
  const closeChangePasswordDialog = () => {
    setIsOpenChangePasswordDialog(false);
  }

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState<boolean>(false);
  const handleCloseConfirmDialog = () => {
    setIsShowConfirmDialog(false);
  }
  const showConfirmDialog = () => {
    setIsShowConfirmDialog(true);
  }
  // const personalInfo = useMemo(() => {
  // return props.updater?.currentState?.personalInfo;
  // }, [props.updater?.currentState?.personalInfo]);

  const personalInfo = useMemo(() => {
    return props.updater?.currentState?.personalInfo;
  }, [props.updater?.currentState?.personalInfo]);

  const prevPersonalInfo = useRef<PersonalInfoDto>(personalInfo);


  useEffect(() => {
    if (personalInfo && !isEqual(personalInfo, prevPersonalInfo.current)) {
      props.updater.updatePartially();
    }
    prevPersonalInfo.current = personalInfo;
  }, [personalInfo]);

  return (
    <>
      { isOpenChangePasswordDialog && (
        <ChangePasswordDialog
          close={closeChangePasswordDialog}
          showConfirmDialog={showConfirmDialog}
        />
      )}
      {isShowConfirmDialog && (
        <CustomConfirmDialog
          title={t("ui:title.confirm")}
          isHiddenCloseButton={true}
          subTitle={t("ui:change_password.confirm_text")}
          onConfirm={handleCloseConfirmDialog}
          open={isShowConfirmDialog}
          buttonText={{
            confirm: "Ok"
          }}
        />
      )}
      {/*<div className="flex-grow-1 d-stack-column spacing-3">*/}
      <Spin spinning={false}>
        <div className="d-flex flex-column" style={{ height: "100%" }}>
          <div className="flex-grow-1 d-stack-column spacing-3" style={{ overflow: "auto" }}>
            {!props.firstLaunch && (
              <>
                <div>
                  <SubheaderText noBottomOffset text={t("ui:subheader.avatar")} />
                </div>
                <div className="mb-3 py-2" style={{ borderRadius: "8px", border: `1px solid ${token.colorBorder}` }}>
                  <AvatarUserSettings
                    imageUrl={props.updater.currentState?.avatar?.image?.url ?? ""}
                    userId={props.updater.currentState?.id}
                    color={props.updater.currentState?.color ?? undefined}
                    text={[props.updater.currentState?.lastName, props.updater.currentState?.firstName]}
                    onUploadSuccess={props.handleAvatarChange}
                    onDeleteClick={props.handleAvatarDelete}
                  />
                </div>
              </>
            )}

            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.last_name")} />
            </div>
            <Tooltip title={t("parse:input_field_validation_only_letters")} trigger={["focus"]} placement="bottomLeft">
              <Input
                // disabled={!props.updater.currentState?.lastName && !props.firstLaunch}
                placeholder={t("ui:placeholder.last_name")}
                value={props.updater.currentState?.lastName ?? ""}
                // value={props.updater.currentState?.lastName ?? t("text:loading_data")}
                onInput={(event) => {
                  props.updater.applyChanges({
                    lastName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
                  });
                  props.setCanShowError({ ...props.canShowError, lastName: true });
                }}
                // onPaste
                onBlur={props.updater.currentState?.lastName?.length > 0 ? props.updater.updatePartially : undefined}
                // error={!(props.updater.currentState?.lastName?.length > 0) && props.canShowError.lastName}
                // helperText={
                //   props.updater.currentState?.lastName?.length > 0 || !props.canShowError.lastName
                //     ? undefined
                //     : t("ui:helper.error_textfield")
                // }
              />
            </Tooltip>
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.first_name")} />
            </div>
            <Tooltip trigger={["focus"]} title={t("parse:input_field_validation_only_letters")} placement="bottomLeft">
              <Input
                // disabled={!props.updater.currentState?.firstName && !props.firstLaunch}
                placeholder={t("ui:placeholder.first_name")}
                value={props.updater.currentState?.firstName ?? ""}
                // onChange={() => props.setCanShowError({ ...props.canShowError, firstName: true })}
                // value={props.updater.currentState?.firstName ?? t("text:loading_data")}
                onInput={(event) => {
                  props.updater.applyChanges({
                    firstName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
                  });
                  props.setCanShowError({ ...props.canShowError, firstName: true });
                }}
                onBlur={props.updater.currentState?.firstName?.length > 0 ? props.updater.updatePartially : undefined}
                // error={!(props.updater.currentState?.firstName?.length > 0) && props.canShowError.firstName}
                // helperText={
                //   props.updater.currentState?.firstName?.length > 0 || !props.canShowError.firstName
                //     ? undefined
                //     : t("ui:helper.error_textfield")
                // }
              />
            </Tooltip>
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.middle_name")} />
            </div>
            <Tooltip trigger={["focus"]} title={t("parse:input_field_validation_only_letters")} placement="bottomLeft">
              <Input
                // disabled={!props.updater.currentState?.middleName && !props.firstLaunch}
                placeholder={t("ui:placeholder.middle_name")}
                value={props.updater.currentState?.middleName ?? ""}
                // value={props.updater.currentState?.middleName ?? t("text:loading_data")}
                onInput={(event) =>
                  props.updater.applyChanges({
                    middleName: removeSpecialCharactersFromString((event.target as HTMLInputElement).value),
                  })
                }
                onBlur={props.updater.updatePartially}
              />
            </Tooltip>
            <div>
              <SubheaderText text={t("ui:subheader.birth_date")} />
              <DatePicker
                style={{ width: "100%" }}
                value={personalInfo?.birthDate
                  ? dayjs(personalInfo?.birthDate)
                  : null
                }
                placeholder={t("ui:placeholder.select")}
                onChange={(value) => {
                  props.updater.applyChanges({
                    personalInfo: {
                      ...props.updater.currentState?.personalInfo,
                      birthDate: value ? dayjs(value).format("YYYY-MM-DD") : null,
                    },
                  });
                }}
                // onOpenChange={handleOpenChangeBirthDate}
                allowClear
                size={"large"}
              />
            </div>
            {!props.firstLaunch && (
              <>
                <div>
                  <SubheaderText noBottomOffset text={t("ui:subheader.email")} />
                </div>
                <Input
                  // disabled={!props.updater.currentState?.email && !props.firstLaunch}
                  placeholder={t("ui:placeholder.email")}
                  // helperText={props.updater?.isEmailConfirmed ? undefined : "Почта не подтверждена!"}
                  value={props.updater.currentState?.email ?? ""}
                  // value={props.updater.currentState?.email ?? t("text:loading_data")}
                  onInput={(event) => props.updater.applyChanges({ email: (event.target as HTMLInputElement).value })}
                  onBlur={props.updater.updatePartially}
                />
                {!props.isAccessTypeEditAvailable && (
                  <Button
                    onClick={handleClickChangePassword}
                    variant="outlined"
                    className="flex-grow-1"
                  >
                    {t("ui:button:change_password")}
                  </Button>
                )}
                {!props.employeeData?.isEmailConfirmed && props.userId == props.currentUserId && (
                  <>
                    <div>
                      <SubheaderText noBottomOffset text={t("ui:subheader.email_confirmation")} />
                    </div>
                    <div className="d-flex flex-column mb-3">
                      <Input
                        value={props.checkEmailCode}
                        onInput={(event) => props.setCheckEmailCode((event.target as HTMLInputElement).value)}
                        placeholder={t("ui:placeholder.email_confirmation_code")}
                      />
                      <div className="d-stack spacing-2 mt-2">
                        <Button
                          onClick={() => props.handleConfirmEmail()}
                          disabled={props.checkEmailCode.length == 0}
                          variant="filled"
                          className="flex-grow-1"
                        >
                          {t("ui:button.check_code")}
                        </Button>
                        <Button
                          onClick={() => props.sendConfirmationCodeAgain()}
                          disabled={props.codeSendTimer != 0}
                          variant="outlined"
                          className="flex-grow-1"
                        >
                          {`${t("parse:send_code")}${props.codeSendTimer > 0 ? " (" + props.codeSendTimer + ")" : ""}`}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.phone_number")} />
            </div>
            <InputPhoneNumber
              defaultErrorMessage={t("ui:helper.error_textfield")}
              value={props.updater.currentState?.phoneNumber ?? ""}
              onChange={(value) => {
                props.updater.applyChanges({ phoneNumber: value });
                props.setCanShowError({ ...props.canShowError, phoneNumber: true });
              }}
              onBlur={props.updater.currentState?.phoneNumber?.length > 0 ? props.updater.updatePartially : undefined}
              isValid={(value, country) => {
                if (value?.trim().length == 0 && props.canShowError.phoneNumber) {
                  return false;
                } else {
                  return true;
                }
              }}
            />
            {/*<PhoneInputField*/}
            {/*  value={props.updater.currentState?.phoneNumber ?? ""}*/}
            {/*  onChange={(value) => {*/}
            {/*    props.updater.applyChanges({ phoneNumber: value });*/}
            {/*    props.setCanShowError({ ...props.canShowError, phoneNumber: true });*/}
            {/*  }}*/}
            {/*  onBlur={props.updater.currentState?.phoneNumber?.length > 0 ? props.updater.updatePartially : undefined}*/}
            {/*  isValid={(value, country) => {*/}
            {/*    if (value?.trim().length == 0 && props.canShowError.phoneNumber) {*/}
            {/*      return false;*/}
            {/*    } else {*/}
            {/*      return true;*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  defaultErrorMessage={t("ui:helper.error_textfield")}*/}
            {/*/>*/}
            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.time_zone")} />
            </div>
            <Select
              // disabled={!props.updater.currentState?.timeZoneFromUtc}
              placeholder={t("ui:placeholder.time_zone")}
              value={props.updater.currentState?.timeZoneId}
              onChange={(value) => props.updater.applyChanges({ timeZoneId: value })}
              onBlur={props.updater.updatePartially}
              fullWidth
              optionLabelProp="label"
              items={timeZones.map((tz, index) => ({
                id: index,
                value: tz.id!,
                text: tz.idWithUtc!,
                additionalProps: { label: tz.idWithUtc },
              }))}
              status={(props.updater.currentState?.timeZoneId ?? "").trim().length == 0 ? "error" : undefined}
            />

            <div>
              <SubheaderText noBottomOffset text={t("ui:subheader.communication_type")} />
            </div>
            <div className="d-stack spacing-2">
              <Select
                placeholder={t("ui:placeholder.preferred_communication_name")}
                value={props.updater.currentState?.contact?.key ?? null}
                onChange={(value) =>
                  props.updater.applyChanges({
                    contact: { ...props.updater.currentState?.contact, key: value },
                  })
                }
                style={{ flexBasis: "50%" }}
                // sx={{
                //   mb:
                //     (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
                //     helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
                //       null &&
                //     !matchExact(
                //       parseRegex(
                //         helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)
                //           ?.regex
                //       ),
                //       props.updater.currentState?.contact?.value
                //     )
                //       ? "1.5em"
                //       : undefined,
                // }}
                onBlur={props.updater.updatePartially}
              >
                {helperStore.getUserContactType?.map((uc) => (
                  <SelectOption value={uc.key as string} key={uc.key as string}>
                    {uc.name}
                  </SelectOption>
                ))}
              </Select>

              <Input
                placeholder={t("ui:placeholder.preferred_communication_value")}
                value={props.updater.currentState?.contact?.value?.trim() ?? ""}
                disabled={!props.updater.currentState?.contact?.key}
                onInput={(event) =>
                  props.updater.applyChanges({
                    contact: {
                      ...props.updater.currentState?.contact,
                      value: (event.target as HTMLInputElement).value?.trim(),
                    },
                  })
                }
                // helperText={
                //   (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
                //   helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
                //     null &&
                //   !matchExact(
                //     parseRegex(
                //       helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex
                //     ),
                //     props.updater.currentState?.contact?.value
                //   )
                //     ? "Неверный формат"
                //     : undefined
                // }
                // error={
                //   (props.updater.currentState?.contact?.value ?? "").trim().length > 0 &&
                //   helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
                //     null &&
                //   !matchExact(
                //     parseRegex(
                //       helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex
                //     ),
                //     props.updater.currentState?.contact?.value
                //   )
                // }
                // TODO: Вернуть текст ошибки
                onBlur={props.updater.updatePartially}
                // onBlur={
                //   (!(
                //     helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)?.regex !=
                //       null &&
                //     !matchExact(
                //       parseRegex(
                //         helperStore.getUserContactType?.find((ct) => ct.key == props.updater.currentState?.contact?.key)
                //           ?.regex
                //       ),
                //       props.updater.currentState?.contact?.value
                //     )
                //   ) ||
                //     (props.updater.currentState?.contact?.value as string)?.trim().length == 0) &&
                //   props.updater.updatePartially
                // }
              />
            </div>

            {isHasEditPermission && (
              <>
                <div>
                  <SubheaderText noBottomOffset text={t("parse:access")} />
                </div>
                <Select
                  readOnly={props.isUserTypeEditAvailable ? false : props.updater.currentState?.currentAccessType == 1}
                  // readOnly={!props.isUserTypeEditAvailable && props.updater.currentState?.currentAccessType != 1}
                  // disabled={!props.updater.currentState?.timeZoneFromUtc}
                  placeholder={t("parse:access")}
                  value={props.updater.currentState?.currentAccessType ?? 0}
                  onChange={(value) => {
                    props.updater.applyChanges({ currentAccessType: value });
                    props.updater.update();
                  }}
                  // onBlur={props.updater.updatePartially}
                  fullWidth
                >
                  {accessTypes.map((aT) => (
                    <SelectOption key={aT.value} value={aT.value}>
                      {aT.text}
                    </SelectOption>
                  ))}
                </Select>
              </>
            )}
            <div>
              <SubheaderText text={t("ui:subheader.employment_date")} />
              <DatePicker
                style={{ width: "100%" }}
                value={personalInfo?.employmentDate
                  ? dayjs(personalInfo?.employmentDate)
                  : null
                }
                placeholder={t("ui:placeholder.select")}
                onChange={(value) =>
                  props.updater.applyChanges({
                    personalInfo: {
                      ...props.updater.currentState?.personalInfo,
                      employmentDate: value ? dayjs(value).format("YYYY-MM-DD") : null
                    },
                  })
                }
                allowClear
                disabled={!isHasEditPermission}
                size={"large"}
              />
            </div>
            {props.firstLaunch && (
              <div className="d-flex justify-end">
                <Button
                  onClick={props.onSaveClick}
                  disabled={
                    !props.updater.currentState?.firstName?.trim()?.length ||
                    !props.updater.currentState?.lastName?.trim()?.length ||
                    !props.updater.currentState?.phoneNumber?.length ||
                    !props.updater.currentState?.timeZoneId?.length
                  }
                  variant="filled"
                >
                  {t("ui:button.save")}
                </Button>
              </div>
            )}
          </div>
          {/*{window.location.origin === "https://my.tonnus.io/" && authStore.getCurrentCompanyId !== 20 ? null : (*/}
          <div
            className="employee-card-footer"
          >
            <TelegramBotFeature />
            <GoogleAuthConnect />
          </div>
          {/*)}*/}
        </div>
      </Spin>
    </>
  );
}

export default observer(EmployeeEditFormView);
