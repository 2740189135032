import React, { useEffect, useMemo, useState } from "react";
import PageContent from "../../modules/layouts/pageContent/PageContent";
import "./SchedulePage.scss";
import { observer } from "mobx-react-lite";
import PageHeader from "../../modules/layouts/pageHeader/PageHeader";
import { Card, Switch } from "../../uiKit";
import { AutocompleteAsync } from "../../elements/autocompleteAsync/AutocompleteAsync";
import { OrgchartSwitcher } from "../../elements/orgchartSwitcher/OrgchartSwitcher";
import { useTranslation } from "react-i18next";
import { LocalStorageHelpers, useApi, useLocalStorage, useRootStore } from "../../../hooks";
import { PopupMenuItemTypes } from "../../service/dropdownMenu/dropdownMenu.interface";
import { usePagingWithController } from "../../../hooks/usePaging";
import { api } from "../../../services";
import dayjs, { Dayjs } from "dayjs";
import { ScheduleDatesList } from "../../modules/pages/schedule/scheduleDatesList/ScheduleDatesList";
import { ScheduleGrid } from "../../modules/pages/schedule/scheduleGrid/ScheduleGrid";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { theme } from "antd";
import { ScheduleCellContextMenu } from "../../modules/pages/schedule/scheduleCellContextMenu/ScheduleCellContextMenu";
import {
  IScheduleSetupDialogConfig,
  ScheduleSetupDialog,
  TScheduleSetupDialogType,
} from "../../modules/pages/schedule/scheduleSetupDialog/ScheduleSetupDialog";
import { User2RoleDto, UserScheduleDto } from "../../../api";
import { DropdownMenu } from "../../service/dropdownMenu/dropdownMenu";

export interface ISchedulePageExpandedRolesStateItem {
  companyId?: number;
  orgchartId?: number;
  isAllCollapsed?: boolean;
  invertedRolesIds?: number[];
}

export interface ISchedulePageCellMenuOptions {
  x: number | null;
  y: number | null;
  isOpen: boolean;
}

const initialSetupDialogConfig: IScheduleSetupDialogConfig = {
  dateFrom: null,
  dateTo: null,
  type: null,
  user2role: null,
};

function SchedulePage() {
  const { t } = useTranslation();
  const { authStore, orgchartStore, appStore } = useRootStore();
  const { useToken } = theme;

  const [zoom, setZoom] = useLocalStorage<number>("zoomSchedule", 100);
  const [userToFilter, setUserToFilter] = useState<null | number>(null);
  const [useUserTimeZone, setUseUserTimeZone] = useState<boolean>(false);
  const [periodLength, setPeriodLength] = useState<number>(14);
  const [isPeriodDatePickerOpen, setIsPeriodDatePickerOpen] = useState<boolean>(false);
  const [selectionResetTrigger, setSelectionResetTrigger] = useState<number>(0);
  const [currentOrgchartId, setCurrentOrgchartId] = useState<number | null>(orgchartStore.getCurrentOrgchartId ?? null);
  const [menuCoordinates, setMenuCoordinates] = useState<ISchedulePageCellMenuOptions>({
    x: 0,
    y: 0,
    isOpen: false,
  });
  const [setupDialogConfig, setSetupDialogConfig] = useState<IScheduleSetupDialogConfig>({
    ...initialSetupDialogConfig,
  });
  const [currentCollapsedRolesState, setCurrentCollapsedRolesState] =
    useState<ISchedulePageExpandedRolesStateItem | null>(() => getCollapsedRolesStateFromLS());

  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs
      .utc({
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
      .startOf("week")
  );
  const endDate: Dayjs = useMemo(() => startDate.add(periodLength - 1, "day").endOf("day"), [startDate]);

  const datesToDisplay: Dayjs[][] = useMemo(() => {
    let _startDate = startDate;

    if (!_startDate.isBefore(endDate)) {
      throw new Error("Начальная дата должна быть раньше конечной даты");
    }

    let result: Dayjs[][] = [];
    let dates: Dayjs[] = [];

    while (_startDate.isSameOrBefore(endDate)) {
      dates.push(_startDate.clone());
      _startDate = _startDate.add(1, "day");
    }

    for (const date of dates) {
      const monthIndex = result.findIndex((arr) => arr[0]?.month() === date.month());
      if (monthIndex === -1) {
        result.push([date]);
      } else {
        result[monthIndex].push(date);
      }
    }

    return result;
  }, [startDate, endDate]);

  const setDialogToSetupDayType = (val: any) => {
    //
  };

  const handlePreviousPeriodClick = (event: any) => {
    event.stopPropagation();
    setStartDate(dayjs(startDate).add(-periodLength, "day"));
  };

  const handleNextPeriodClick = (event: any) => {
    event.stopPropagation();
    setStartDate(dayjs(startDate).add(periodLength, "day"));
  };

  const handlePeriodPickerChange = (value: Dayjs | null) => {
    if (value == null) return;
    setStartDate(dayjs.utc(value).startOf("week"));
    setIsPeriodDatePickerOpen(false);
  };

  const handleSelectionFinish = (selectedItems: any[], user2role: User2RoleDto) => {
    if (selectedItems.length == 0) return;
    const selectedItemBounds = selectedItems[selectedItems.length - 1]?.bounds?.[0];
    if (selectedItemBounds == null) return;
    setMenuCoordinates({
      x: (selectedItemBounds.left ?? 0) - (appStore.getLeftDrawerState == "__collapsed" ? 64 : 248),
      y: (selectedItemBounds.top ?? 0) + (selectedItemBounds.height ?? 0) - 4 - 128,
      isOpen: true,
    });
    const sortedSelectedItems = selectedItems?.sort((a, b) => (a.props.index as number) - (b.props.index as number));
    setSetupDialogConfig({
      dateFrom: datesToDisplay.flat()[sortedSelectedItems[0].props.index],
      dateTo: datesToDisplay.flat()[sortedSelectedItems[sortedSelectedItems.length - 1].props.index].endOf("day"),
      type: null,
      user2role: user2role,
    });
  };

  const handleSelectionReset = (event?: any) => {
    const isCellMenu = event != null ? (event.target as HTMLDivElement).closest(".schedule__cell-menu") : null;
    if (isCellMenu != null) return;
    setSelectionResetTrigger(selectionResetTrigger > 5 ? 0 : selectionResetTrigger + 1);
    setMenuCoordinates((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCellMenuItemClick = (type: TScheduleSetupDialogType) => {
    setMenuCoordinates((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setSetupDialogConfig((prev) => ({ ...prev, type }));
  };

  const handleSetupDialogCancel = () => {
    handleSelectionReset();
    setSetupDialogConfig((prev) => ({ ...initialSetupDialogConfig }));
  };

  const handleSetupDialogSave = (userSchedule: UserScheduleDto, forceReload?: boolean) => {
    if (forceReload == true || !!setupDialogConfig.user2role?.hasAnyScheduleWithSubstitute) {
      rolesHistory.reset();
      rolesHistory.restart();
    } else {
      // handleUserRowUpdate(s);
      rolesHistory.reset();
      rolesHistory.restart();
    }
  };

  const handleRowExpandChange = (roleId: number) => {
    const currentInvertedRolesIds = currentCollapsedRolesState?.invertedRolesIds ?? [];
    const areAllRolesCollapsed = currentCollapsedRolesState?.isAllCollapsed;
    const isCurrentRoleCollapsed =
      (areAllRolesCollapsed && !currentCollapsedRolesState?.invertedRolesIds?.includes(roleId)) ||
      (!areAllRolesCollapsed && currentCollapsedRolesState?.invertedRolesIds?.includes(roleId));

    setCurrentCollapsedRolesState({
      ...currentCollapsedRolesState,
      invertedRolesIds: isCurrentRoleCollapsed
        ? areAllRolesCollapsed
          ? [...currentInvertedRolesIds, roleId]
          : [...currentInvertedRolesIds].filter((i) => i != roleId)
        : areAllRolesCollapsed
        ? [...currentInvertedRolesIds].filter((i) => i != roleId)
        : [...currentInvertedRolesIds, roleId],
    });
  };

  function getCollapsedRolesStateFromLS(): ISchedulePageExpandedRolesStateItem | null {
    const lsValue = (LocalStorageHelpers.get("scheduleCollapsedRolesState") ??
      []) as ISchedulePageExpandedRolesStateItem[];
    if (currentOrgchartId == null || authStore.getCurrentCompanyId == null) return null;
    const _currentState =
      lsValue?.find((s) => s.companyId == authStore.getCurrentCompanyId && s.orgchartId == currentOrgchartId) ?? null;
    if (_currentState != null) return _currentState;
    return {
      orgchartId: currentOrgchartId,
      companyId: authStore.getCurrentCompanyId,
      isAllCollapsed: false,
      invertedRolesIds: [],
    };
  }

  const rolesHistory = usePagingWithController(
    api.role,
    {
      userDateFrom: startDate.toISOString(),
      userDateTo: endDate.toISOString(),
      includeSchedules: true,
      useScheduleContext: true,
      orgchartId: currentOrgchartId ?? null,
      // @ts-ignore
      userIds: userToFilter != null ? [userToFilter] : null,
    },
    { pageSize: 40 }
  );

  const defaultSchedule = useApi(() =>
    api.userSchedule.default({
      dateFrom: startDate.toISOString(),
      dateTo: endDate.toISOString(),
    })
  );

  const menuItems: PopupMenuItemTypes[] = [
    {
      id: 0,
      text: t("common:misc.collapse_functions"),
      action: () =>
        setCurrentCollapsedRolesState({ ...currentCollapsedRolesState, isAllCollapsed: true, invertedRolesIds: [] }),
      disabled:
        rolesHistory.items.length == 0 ||
        (currentCollapsedRolesState?.isAllCollapsed && currentCollapsedRolesState?.invertedRolesIds?.length == 0),
    },
    {
      id: 1,
      text: t("common:misc.expand_functions"),
      action: () =>
        setCurrentCollapsedRolesState({ ...currentCollapsedRolesState, isAllCollapsed: false, invertedRolesIds: [] }),
      disabled:
        rolesHistory.items.length == 0 ||
        (!currentCollapsedRolesState?.isAllCollapsed && currentCollapsedRolesState?.invertedRolesIds?.length == 0),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      const lsValue = (LocalStorageHelpers.get("scheduleCollapsedRolesState") ??
        []) as ISchedulePageExpandedRolesStateItem[];
      const newLsValue = lsValue.some(
        (val) => val.companyId == authStore.getCurrentCompanyId && val.orgchartId == currentOrgchartId
      )
        ? lsValue.map((val) =>
            val.companyId == authStore.getCurrentCompanyId && val.orgchartId == currentOrgchartId
              ? currentCollapsedRolesState
              : val
          )
        : [...lsValue, currentCollapsedRolesState];
      LocalStorageHelpers.set("scheduleCollapsedRolesState", newLsValue);
    }, 0);
  }, [currentCollapsedRolesState]);

  useEffect(() => {
    rolesHistory.restart();
  }, []);

  useEffect(() => {
    rolesHistory.reset();
    rolesHistory.restart();
    defaultSchedule.fetch();
  }, [endDate, userToFilter, currentOrgchartId]);

  return (
    <>
      <ScheduleSetupDialog
        config={setupDialogConfig}
        onCancel={handleSetupDialogCancel}
        onSave={handleSetupDialogSave}
      />

      <PageHeader>
        <Card className="full-width" bodyStyle={{ padding: "8px" }}>
          <div className="d-flex align-center justify-space-between">
            <div className="d-stack spacing-2 align-center">
              <AutocompleteAsync
                style={{ width: "220px" }}
                size="middle"
                allowClear
                value={userToFilter}
                type="user"
                placeholder={t("ui:placeholder.search_by_employees")}
                onChange={(value: string | null) => {
                  setUserToFilter(value != null ? Number(value) : null);
                }}
              />
              <OrgchartSwitcher
                drawType="select"
                preventDefault
                selectProps={{
                  size: "middle",
                  style: {
                    width: "220px",
                  },
                }}
                value={currentOrgchartId ?? undefined}
                onChange={(v) => typeof v === "number" && setCurrentOrgchartId(v)}
              />
              <Switch
                label={t("common:misc.display_by_employee_timezone")}
                checked={useUserTimeZone}
                onChange={setUseUserTimeZone}
              />
            </div>
            <div className="d-stack spacing-2 align-center">
              {/*<ZoomButton value={zoom} onChange={setZoom} />*/}
              <DropdownMenu items={menuItems} />
            </div>
          </div>
        </Card>
      </PageHeader>
      <PageContent
        // zoom={{ percentage: zoom, strategy: "zoom" }}
        style={{ position: "relative" }}
        innerDivProps={{
          onMouseDown: handleSelectionReset,
          // onScroll: (e) => (menuCoordinates.x != 0 && menuCoordinates.y != 0 ? e.preventDefault() : null),
        }}
      >
        <ScheduleCellContextMenu menuCoordinates={menuCoordinates} onMenuItemClick={handleCellMenuItemClick} />
        <ScheduleDatesList
          isPeriodPickerOpen={isPeriodDatePickerOpen}
          datesToDisplay={datesToDisplay}
          periodPickerDate={{ start: startDate, end: endDate }}
          onPeriodPickerOpenChange={setIsPeriodDatePickerOpen}
          onPeriodPickerPreviousClick={handlePreviousPeriodClick}
          onPeriodPickerChange={handlePeriodPickerChange}
          onPeriodPickerNextClick={handleNextPeriodClick}
        />
        <ScheduleGrid
          datesToDisplay={datesToDisplay.flat()}
          periodPickerDate={{ start: startDate, end: endDate }}
          roles={{
            items: rolesHistory.items,
            isLoading: rolesHistory.info.isLoading,
            isDone: rolesHistory.info.isDone,
          }}
          useUserTimeZone={useUserTimeZone}
          defaultSchedule={{ items: defaultSchedule.value ?? [] }}
          selectionResetTrigger={selectionResetTrigger}
          currentCollapsedRolesState={currentCollapsedRolesState}
          onRowExpandChange={handleRowExpandChange}
          onSelectionFinish={handleSelectionFinish}
        />
        <ScrollTrigger
          marginTop={rolesHistory.items.length > 0}
          hidden={rolesHistory.info.isDone}
          disabled={rolesHistory.info.isLoading}
          onIntersection={rolesHistory.loadNext}
        />
      </PageContent>
    </>
  );
}

export default observer(SchedulePage);
