import React, { useMemo, useState, useCallback, memo } from "react";
import ScrollTrigger from "../../service/scrollTrigger/ScrollTrigger";
import { IHistoryListCards } from "./History.interface";
import { HistoryCard } from "./HistoryCard";
import { useRootStore } from "../../../hooks";
import { HistoryDto } from "../../../api";
import "./HistoryListCards.scss";


export const HistoryListCards = memo((props: IHistoryListCards) => {
  const { authStore } = useRootStore();
  const user = useMemo(() => {
    return authStore.getInitialInfo?.identity ?? undefined
  }, [authStore.getInitialInfo?.identity]);

  const [editCommentId, setEditCommentId] = useState<number>(-1);

  const handleEditClick = useCallback((id) =>{
    setEditCommentId(id)
  }, []);

  const handleSaveClick = useCallback(async (id: number, data: HistoryDto) => {
    const status = await props.handleUpdateComment(id, data);
    setEditCommentId(-1);
    return status;
  }, [props.historyItems]);

  return (
    <div className="history-list spacing-4 d-stack-column justify-start align-center">
      {props.historyItems.map((item, index) => (
        <HistoryCard
          key={item.id}
          historyId={item.id!}
          index={index}
          item={item}
          dateCreated={item.dateCreated!}
          reactions={item.reactions ?? []}
          creatorId={item.createdByUserId!}
          creator={item.createdByUser!}
          isEditing={item.createdByUserId === user?.id}
          editCommentId={editCommentId}
          commentContent={item.comment?.content ?? ""}
          // @ts-ignore
          commentAttachments={item.comment?.attachments ?? []}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          handleRemoveComment={props.handleRemoveComment}
        />
      ))}
      {props.historyItems.length !== 0 && (
        <ScrollTrigger
          fullWidth
          disabled={props.isDone}
          onIntersection={props.loadNext}
          marginTop={props.historyItems.length > 0}
          hidden={props.isDone}
        />
      )}

    </div>
  );
});
